import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  HostListener,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { EtsTicketService } from '../ets-ticket-service/ets-ticket-service';
import {
  EtsCategoryData,
  EtsSeatmapOptions,
  EtsSeatmapTickets,
  EtsSeatmapTooltipData,
  EtsTicketData,
} from '../ets-ticket-service/ets-ticket-data';
import { TranslateService } from '@ngx-translate/core';
import { EtsBasketService } from '../ets-basket-service/ets-basket-service';
import { BaseComponent } from '../base-component/base-component';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EtsComponentRouterService } from '../ets-component-router-service/ets-component-router.service';
import { EtsDetailService } from '../ets-detail-service/ets-detail.service';
import { EtsDetailData } from '../ets-detail-service/ets-detail-data';
import { EtsDetailPackageData } from '../ets-detail-package-service/ets-detail-package-data';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-ets-ticket',
  templateUrl: './ets-ticket.component.html',
  styleUrls: ['./ets-ticket.component.css', './jquery.seatmap-min.css'],
  changeDetection:ChangeDetectionStrategy.Default,
})
export class EtsTicketComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren('amountSelect') amountSelects: QueryList<any>;
  @ViewChild('seatmapv5') seatmap;
  @ViewChild('ticketid') set ticketid(input: ElementRef | null) {
    if (!input) {
      return;
    }
    if (typeof this.ticketId === 'undefined') {
      this.ticketId = input;
      this.ticketId.nativeElement.style.height = 'auto';
    }
  }
  @ViewChild('planid') planid;
  @ViewChild('tooltip') tooltip;
  @ViewChild('seatmapHolder') seatmapHolder;
  @ViewChild('seatmapContainer') seatmapContainer;
  @ViewChild('overlay') overlay;
  @Input() identifier: string;
  @Input() embeddedIn: string;
  @Input() embedName: string;
  oldIdentifier: string;
  details: EtsTicketData;
  eventData: EtsDetailData | EtsDetailPackageData;
  categoryData: EtsCategoryData[];
  filterCategories: EtsCategoryData[];
  ticketData = [];
  allowAddToBasket: boolean;
  ticketId: ElementRef;
  basket: {};
  showPlan: boolean;
  seatmapTickets: EtsSeatmapTickets[];
  priceTotal: number;
  showBlockTooltip: boolean;
  showSeatTooltip: boolean;
  overlayActive: boolean;
  isUpdating: boolean;
  seatMapInitialized: boolean;
  globalConfig: any;
  seatmapObject: any;
  packageID: number;
  showPackageButton: boolean;
  tabSwitch: boolean;
  packageButtonActive: boolean;

  seatmapOptions: EtsSeatmapOptions;
  filteredCatIds: any[] = [];
  objectKeys = Object.keys;

  priceCategories;
  seatmapTooltip: EtsSeatmapTooltipData;
  isPackage: boolean;

  top: number;
  left: number;

  constructor(
    public ticketService: EtsTicketService,
    private detailService: EtsDetailService,
    public translate: TranslateService,
    public basketService: EtsBasketService,
    public location: Location,
    public config: EtsConfigService,
    private toastr: ToastrService,
    private routerService: EtsComponentRouterService,
    private spinnerService: NgxSpinnerService,
    private ref: ChangeDetectorRef
  ) {
    super();
    this.isUpdating = true;
    this.seatMapInitialized = false;
  }

  ngOnInit(): void {
    this.isUpdating = true;
    this.showPackageButton = false;
    this.categoryData = [];
    this.filterCategories = [];
    this.seatmapTickets = [];
    this.basket = {};
    this.showPlan = false;
    this.packageButtonActive = false;
    this.top = 0;
    this.left = 0;
    this.priceTotal = 0;
    this.filteredCatIds = [];
    this.seatmapTooltip = new EtsSeatmapTooltipData();
    this.seatmapTooltip.info = '';
    this.seatmapTooltip.seatViewUrl = '';
    this.overlayActive = false;
    this.isPackage = false;
    this.loadEventInformations();
    this.allowAddToBasket = false;
    this.ticketData = [];


  }

  ngAfterViewInit(): void {
    this.tooltip.nativeElement.mouseleave = () => {
      if (this.tooltip.nativeElement.classList.contains('etsOpened')) {
        this.tooltip.nativeElement.classList.remove('etsOpened');
      }
    };

    if (this.showPlan) {
      this.showTab('plan');
    } else {
      this.showTab('ticket');
    }

    this.tooltip.nativeElement.mouseenter = () => {
      this.tooltip.nativeElement.classList.add('entered');
    };
  }

  /**
   * Loads event informations and prices
   * @returns void
   */
  loadEventInformations(): void {
    this.ticketService.loadTicketData(this.identifier).subscribe((details) => {
      this.oldIdentifier = null;
      this.globalConfig = this.config.getConfigObject();
      if (details !== null) {
        this.oldIdentifier = this.identifier;
        this.details = details;
        if (this.details.tickets[0]) {
          this.priceCategories = {};
          for (const priceG of this.details.tickets) {
            if (!this.priceCategories[priceG.internalKey]) {
              this.priceCategories[priceG.internalKey] = {
                id: priceG.priceGroupId,
                title: priceG.categoryName,
                color: priceG.eventDateId.substring(0, 6),
                price: priceG.price,
                seatGroup: priceG.seatGroup,
                priceFormatted: priceG.price,
                priceGroups: [
                  {
                    id: priceG.priceGroupId,
                    title: priceG.priceGroupName,
                    price: priceG.price,
                    priceFormatted: priceG.price,
                    active: true,
                  },
                ],
                totalCount: '',
                connectedCount: '',
              };
            } else {
              this.priceCategories[priceG.internalKey].priceGroups.push({
                id: priceG.priceGroupId,
                title: priceG.priceGroupName,
                price: priceG.price,
                priceFormatted: priceG.price,
                active: true,
              });
            }
          }

          for (const priceG of this.details.tickets) {
            this.categoryData.push({
              id: priceG.eventDateId,
              color: priceG.color,
              eventId: priceG.eventId,
              categoryNumber: priceG.category,
              categoryName: priceG.categoryName,
              priceGroupName: priceG.priceGroupName,
              priceGroupId: priceG.priceGroupId,
              price: priceG.price,
              seatGroup: priceG.seatGroup,
              blockIdentifier: priceG.blockIdentifier,
              internalKey: priceG.internalKey,
            });
          }

          // * Make CategoryNames as unique keys for grouped ticket view.
          this.ticketData = this.details.tickets.reduce((r, a) => {
            r['K' + a.internalKey] = r['K' + a.internalKey] || [];
            r['K' + a.internalKey].push(a);
            return r
          }, Object.create(null));

          // * Remove duplicated categories for seatmap filter.
          this.filterCategories = this.categoryData;
          this.filterCategories = this.filterCategories.filter((value,index,self) =>
            self.findIndex(v2 => (v2.internalKey === value.internalKey)) === index)
        }
        if (typeof $ !== 'undefined' && this.details.ticketSeatmap) {
          let eventIdentifier = this.identifier;
          if (this.identifier.indexOf('777') === 0) {
            eventIdentifier = this.identifier.replace(/777/g, '-');
          }

          if (!this.seatMapInitialized) {
            this.ref.markForCheck();
            this.addJsToElement(
              'https://www.easyticket.de/vendor/seatmapv5/js/jquery.seatmap.js',
              'seatMapJQuery'
            ).onload = () => {
              if (this.details.tickets[0]) {
                this.seatmapOptions = {
                  evId: eventIdentifier,
                  cType: 'inhouse',
                  cId: '157',
                  server: 'https://api.eventim.com',
                  additionalRequestParams:
                    '&a_mandant=001&a_kassierer=' +
                    this.globalConfig.invoice.cashier +
                    '&a_servleturl=' + environment.SEATMAP_URL,
                  priceCategories: this.priceCategories,
                };

                this.seatmapObject = $(this.seatmapContainer.nativeElement);

                this.seatmapObject.seatmap(
                  $.extend(
                    {
                      zoomControl: {
                        enabled: true,
                        position: 'topright',
                      },
                      renderer: {
                        seatRenderer: () => this.seatStyles,
                        linkedBlockRenderer: () => this.linkedBlockStyles,
                        seatGroupRenderer: () => this.seatGroupStyles,
                      },
                      skipHulls: true,
                      seatViewInfos: true,
                    },
                    this.seatmapOptions
                  )
                );
                this.seatMapInitialized = true;
                this.onResize();

                this.seatmapObject.on('linkedblock:mouseenter', (event) => {
                  const block = event.target;
                  const priceCategoriesFormatted = [];
                  const priceCategories = block.getPriceCategories();

                  for (const key in priceCategories) {
                    if (!priceCategories.hasOwnProperty(key)) {
                      continue;
                    }
                    const priceCategory = priceCategories[key];
                    const categoryId = priceCategory.pcId.replace(/^p/gi, '');
                    const priceCategoryObj =
                      this.seatmapOptions.priceCategories[categoryId];

                    priceCategoryObj.totalCount = priceCategory.totalCount;
                    priceCategoryObj.connectedCount =
                      priceCategory.connectedCount;

                    priceCategoriesFormatted.push(priceCategoryObj);
                  }
                  this.showBlockTooltip = true;
                });

                this.seatmapObject.on('linkedblock:mouseleave', () => {
                  this.showBlockTooltip = false;
                });

                this.seatmapObject.on('seat:mouseenter', (event) => {
                  const seatId = event.target.getId();

                  this.seatmapObject.seatmap(
                    'getBlockInfo',
                    event.target,
                    (blockId, blockInfo: any) => {
                      const seatElement: any = blockInfo.seatInfos[seatId];
                      let infos = this.getSeatInfos(blockInfo, seatElement);
                      const viewInfos = blockInfo.seatInfos.viewInfos;

                      let imageUrl = '';
                      if (viewInfos !== undefined) {
                        imageUrl =
                          viewInfos.views[blockInfo.seatInfos[seatId].viewIndex]
                            .url;
                      }

                      if (
                        infos[0].match(/Stehplatz/gi) ||
                        infos[0].match(/Front of Stage/gi)
                      ) {
                        infos = [infos[0]];
                      }

                      this.seatmapTooltip.seatViewUrl = imageUrl;
                      this.seatmapTooltip.info = infos.join(', ');
                      this.showSeatTooltip = true;
                    }
                  );
                });

                this.seatmapObject.on('seat:mouseleave', () => {
                  this.seatmapTooltip.seatViewUrl = '';
                  this.seatmapTooltip.info = '';
                  this.showSeatTooltip = false;
                });

                this.seatmapObject.on('selection:changed', (event) => {
                  this.priceTotal = 0;
                  this.seatmapTickets = [];

                  event.selection.items.forEach((element) => {

                    this.seatmapObject.seatmap(
                      'getBlockInfo',
                      element,
                      (blockId, blockInfo: any) => {
                        const id =
                          element.object.getType() === 'ga'
                            ? element.object.getGAId()
                            : element.object.getId();
                        const seatElement = blockInfo.seatInfos[id];
                        const viewIndex = blockInfo.seatInfos[id].viewIndex;
                        const priceCategory = element.object.getPriceCategory();
                        const categoryId = priceCategory._data.id.replace(
                          /^p/gi,
                          ''
                        );
                        const priceCategoryObj =
                          this.seatmapOptions.priceCategories[categoryId];
                        const seatNumber = id.replace(/^s/gi, '');

                        const viewInfos = blockInfo.seatInfos.viewInfos;
                        let imageUrl;

                        if (viewInfos !== undefined) {
                          imageUrl = viewInfos.views[viewIndex].url;
                        }

                        this.seatmapTickets.push({
                          id,
                          seatNumber:
                            element.object.getType() === 'ga'
                              ? priceCategoryObj.seatGroup
                              : seatNumber.replace(/[^0-9]+/, ''),
                          priceGroups: priceCategoryObj.priceGroups,
                          price: priceCategoryObj.priceFormatted,
                          info: this.getSeatInfos(blockInfo, seatElement).join(
                            ', '
                          ),
                          seatViewUrl: imageUrl,
                          priceGroupId: priceCategoryObj.priceGroups[0].id,
                        });

                        this.priceTotal =
                          this.priceTotal - -priceCategoryObj.price;
                          this.ref.detectChanges();
                      }
                    );
                  });
                  this.ref.detectChanges();
                });
              };
            };
          }
        } else {
          this.details.ticketSeatmap = false;

        }
      }

      this.isUpdating = false;
      this.validatePackageButton();
    });
    if (this.identifier.indexOf('777') !== 0) {
      this.detailService
        .loadDetailData(this.identifier)
        .subscribe((details) => {
          this.eventData = details;
          if (details?.headliner?.packages.length >= 1) {
            this.packageID = details.headliner.packages[0].package_id;
          }
          this.validatePackageButton();
        });
    }
  }

  /**
   * Validationmethod for showing special package button in ticketing component
   *
   * @returns void
   */
  validatePackageButton(): void {
    if (
      this.packageID !== undefined &&
      this.packageID !== null &&
      this.packageID >= 0 &&
      this.globalConfig.ticketing.showPackageInTicketWidget
    ) {
      this.showPackageButton = true;
    } else {
      this.showPackageButton = false;
    }
  }

  /**
   * Toggles Additional booking options view in grouped tickets view.
   *
   * @param event
   */
  openTicketCategoryOptions(index:any): void {
    let expander: Element = document.querySelector('#categoryInfo' + index);
    let categoryContainer: any = expander.previousElementSibling;

    let expanderText: any = expander.querySelector('p#bookingInfos');
    let additionalCategoriesCounter: any = expander.querySelector('div#categoryCount');
    let chevron: any = expander.querySelector('svg#categoryChevron');

    if (categoryContainer.classList.contains('open')) {

      expanderText.textContent = this.translate.instant('ticket.additionalBookingOptions');
      categoryContainer.classList.remove('open');
      categoryContainer.style.maxHeight = '0px';
      additionalCategoriesCounter.style.display = 'block';
      chevron.style.transform = "rotate(0deg)";
      // * Get Previous element (usually *CategoryName* => Normalpreis) and scroll to its center position.
      let category = categoryContainer.previousElementSibling;
      category.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});

    } else {
      // * Calculate total height by sum the height of all childs
      let childs = categoryContainer.children;
      let maxHeight = 0;
      for (let child of childs) {
        maxHeight += child.getBoundingClientRect().height;
      }

      expanderText.textContent = this.translate.instant('ticket.close');

      // * Add class 'open' as state indicator.
      categoryContainer.classList.add('open');
      categoryContainer.style.maxHeight = maxHeight + 'px';

      additionalCategoriesCounter.style.display = 'none';
      chevron.style.transform = "rotate(90deg)";
    }
  }

  back(): void {
    this.location.back();
  }
  numSequence(count: number): Array<number> {
    return Array<number>(count);
  }

  checkAddToCard($event: any): void {
    if ($event.target.value.length > 0) {
      this.basket[$event.target['field-id']] = parseInt(
        $event.target.value,
        10
      );
    } else {
      this.basket[$event.target['field-id']] = 0;
    }

    this.allowAddToBasket = false;
    for (const key in this.basket) {
      if (this.basket.hasOwnProperty(key) && this.basket[key] > 0) {
        this.allowAddToBasket = true;
        break;
      }
    }
  }

  /**
   * Adds event tickets to basket
   *
   * @returns void
   */
  addToBasket(): void {
    this.spinnerService.show('ticketingSpinner');
    const seatData = [];
    for (const key in this.basket) {
      if (this.basket.hasOwnProperty(key) && this.basket[key] > 0) {
        const seat = {
          id: key,
          amount: this.basket[key],
        };
        seatData.push(seat);
        this.basket[key] = 0;
      }
    }
    this.ticketService
      .addBestSeatToBasket(this.identifier, seatData)
      .subscribe((data) => {
        this.amountSelects.forEach(
          (amountSelect) => (amountSelect.nativeElement.value = 0)
        );
        this.allowAddToBasket = false;
        if (data) {
          if (this.embedName === undefined || this.embedName === null) {
            this.embedName = 'Page';
          }
          let successMessage = this.translate.instant(
            'checkout.basket.reservTicket'
          );
          successMessage += ' <span class="etsText">';
          successMessage +=
            '<a class="etsBtn" href="' +
            this.routerService.urlString([
              { name: this.embedName, target: 'basket-detail' },
            ]) +
            '">' +
            this.translate.instant('basket.shoppingcart') +
            '</a>';
          successMessage += '</span>';
          this.toastr.success(successMessage, null, { enableHtml: true });
          this.spinnerService.hide('ticketingSpinner');
        } else {
          this.spinnerService.hide('ticketingSpinner');
        }
      },
      error => {
        console.log(error);
        this.spinnerService.hide('ticketingSpinner');
      });
  }

  /**
   * Adds event tickets from seatmap to basket
   *
   * @returns void
   */
  addSeatmapToBasket(): void {
    this.spinnerService.show('ticketingSpinner');
    const seatNumbers: number[] = [];
    const priceGroups: number[] = [];
    for (const ticket of this.seatmapTickets) {
      seatNumbers.push(parseInt(ticket.seatNumber, 10));
      priceGroups.push(ticket.priceGroupId);
    }
    this.ticketService
      .addSeatmapToBasket(this.identifier,  seatNumbers, priceGroups)
      .subscribe((data) => {
        if (data) {
          if (this.embedName === undefined || this.embedName === null) {
            this.embedName = 'Page';
          }
          let successMessage = this.translate.instant(
            'checkout.basket.reservTicket'
          );
          successMessage += ' <span class="etsText">';
          successMessage +=
            '<a class="etsBtn" href="' +
            this.routerService.urlString([
              { name: this.embedName, target: 'basket-detail' },
            ]) +
            '">' +
            this.translate.instant('basket.shoppingcart') +
            '</a>';
          successMessage += '</span>';
          this.toastr.success(successMessage, null, { enableHtml: true });
          this.spinnerService.hide('ticketingSpinner');

          this.seatmapTickets = [];
          this.priceTotal = 0;
        } else {
          this.spinnerService.hide('ticketingSpinner');
        }
      });
  }

  addJsToElement(src: string, id: string = null): HTMLScriptElement {
    let scriptElement = document.getElementById(id);

    // Remove element if allready exist
    if (scriptElement) {
      scriptElement.remove();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;

    if (id !== null) {
      script.id = id;
    }

    document.getElementsByTagName('head')[0].appendChild(script);
    return script;
  }

  showTab(tab: string): void {
    this.showPlan = tab === 'plan';
    if (this.showPlan === true) {
      if (document.getElementById('planid') !== undefined && document.getElementById('planid') !== null
        && document.getElementById('ticketid') !== undefined && document.getElementById('ticketid') !== null) {
        document.getElementById('planid').setAttribute('style', 'height: auto;');
        document.getElementById('ticketid').setAttribute('style', 'height: 0;');
      }
      if (this.ticketId !== undefined && this.ticketId !== null && this.planid !== undefined && this.planid !== null) {
        this.ticketId.nativeElement.style.height = 0;
        this.planid.nativeElement.style.height = 'auto';
      }

    } else {
      if (document.getElementById('planid') !== undefined && document.getElementById('planid') !== null
        && document.getElementById('ticketid') !== undefined && document.getElementById('ticketid') !== null) {
          document.getElementById('ticketid').setAttribute('style', 'height: auto;');
          document.getElementById('planid').setAttribute('style', 'height: 0;');
      }
      if (this.ticketId !== undefined && this.ticketId !== null && this.planid !== undefined && this.planid !== null) {
        this.planid.nativeElement.style.height = 0;
        this.ticketId.nativeElement.style.height = 'auto';
      }
    }
  }

  /**
   * Navigates customer to package event site
   *
   * @returns void
   */
  openPackage(): void {
    if (typeof EtsDetailData) {

      this.routerService.navigateEmbed([
        { name: this.embedName, target: 'detail-777' + this.packageID },
      ]);
    }
  }

  showOverlay($event: any): void {
    this.overlayActive = true;

    const maxWidth = 750;
    let width;
    const maxWindowWidth = window.innerWidth - 50;

    if (maxWidth > maxWindowWidth) {
      width = maxWindowWidth;
    } else {
      width = maxWidth;
    }

    const firstOpening = !this.tooltip.classList.contains('etsOpened');

    this.overlay.nativeElement.classList.remove('enlargeStyle');

    const originalWidth = $event.target.nativeElement.width;
    $event.target.nativeElement.style.width = width + 'px';

    if (firstOpening) {
      $event.target.nativeElement.style.width = originalWidth + 'px';
    }
  }

  hideOverlay(): void {
    this.overlayActive = false;
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(e: any): void {
    this.tooltip.nativeElement.style.top = e.clientY - -5 + 'px';
    this.tooltip.nativeElement.style.left = e.clientX - -5 + 'px';
  }

  seatStyles(seatObj: any, style: any): any {
    const newStyle = {
      fillColor: '#333333',
      color: '#333333',
    };
    const pcObj = seatObj.getPriceCategory();

    const priceCategory =
      this.seatmapOptions.priceCategories[pcObj._data.id.replace(/^p/, '')];
    if (priceCategory !== undefined) {
      newStyle.fillColor = priceCategory.color;
      newStyle.color = priceCategory.color;
    }

    if (
      seatObj.isMouseOver() ||
      seatObj.isHighlighted() ||
      seatObj.isSelected()
    ) {
      $.extend(newStyle, {
        color: '#000000',
        fillColor: 'var(--globalmainbuttoncolorcontrast)',
        weight: 2,
      });
    }

    return $.extend(style, newStyle);
  }

  linkedBlockStyles(blockObj: any, style: any): any {
    const newStyle = {
      fillColor: '#aaaaaa',
    };

    const priceCategories = blockObj.getPriceCategories();
    let count = 0;

    for (const key in priceCategories) {
      if (!priceCategories.hasOwnProperty(key)) {
        continue;
      }
      if (
        priceCategories[key].totalCount > count &&
        (this.filteredCatIds.length === 0 ||
          $.inArray(key, this.filteredCatIds) >= 0)
      ) {
        count = priceCategories[key].totalCount;
        const priceCategory =
          this.seatmapOptions.priceCategories[key.replace(/^p/, '')];
        if (priceCategory !== undefined) {
          newStyle.fillColor = priceCategory.color;
        }
      }
    }

    return $.extend(style, newStyle);
  }

  seatGroupStyles(seatGroupPart: any, style: any): any {
    const newStyle = {
      fillColor: '#333333',
      color: '#333333',
    };

    const priceCategory =
      this.seatmapOptions.priceCategories[seatGroupPart.pc.replace(/^p/, '')];
    if (
      priceCategory !== undefined &&
      (this.filteredCatIds.length === 0 ||
        $.inArray(seatGroupPart.pc, this.filteredCatIds) >= 0)
    ) {
      newStyle.fillColor = priceCategory.color;
      newStyle.color = priceCategory.color;
    }

    return $.extend(style, newStyle);
  }

  onResize(): void {
    this.seatmapHolder.nativeElement.style.height =
      this.seatmap.nativeElement.outerHeight + 120 + 'px';
    $('.map-container .back-button').css(
      'left',
      this.seatmap.nativeElement.offsetLeft + 10 + 'px'
    );
  }

  getSeatInfos(blockInfo: any, seatElement: any): any[] {
    const lang = {
      notAvailable: ['Nicht verfügbar', 'Not available'],
      seatInfos: {
        entrance: ['Eingang', 'Entrance'],
        row: ['Reihe', 'Row'],
        table: ['Tisch', 'Table'],
        seatNumber: ['Platz', 'Seat'],
      },
    };
    const infos = [];
    infos.push(blockInfo.b_name);
    const infoFields = ['entrance', 'row', 'table', 'seatNumber'];

    for (const infoField of infoFields) {
      const seatInfoValue = seatElement[infoField].trim();

      if (seatInfoValue.length > 0) {
        infos.push(lang.seatInfos[infoField][0] + ' ' + seatInfoValue);
      }
    }

    return infos;
  }

  removeSeat(id: string): void {
    this.seatmapObject.seatmap('deselect', id);
    this.ref.detectChanges();
  }

  changePrice(id: string, $event: any): void {
    const priceCat = $event.target.value;
    if (this.seatmapTickets.findIndex((x) => x.id === id) >= 0) {
      const key = this.seatmapTickets.findIndex((x) => x.id === id);
      if (
        this.seatmapTickets[key].priceGroups.findIndex(
          (x) => x.id === priceCat
        ) >= 0
      ) {
        const ikey = this.seatmapTickets[key].priceGroups.findIndex(
          (x) => x.id === priceCat
        );
        const price = this.seatmapTickets[key].priceGroups[ikey].price;
        this.priceTotal = this.priceTotal - this.seatmapTickets[key].price;
        this.seatmapTickets[key].price = price;
        this.seatmapTickets[key].priceGroupId =
          this.seatmapTickets[key].priceGroups[ikey].id;
        this.priceTotal = this.priceTotal - -this.seatmapTickets[key].price;
      }
    }
  }

  filterByPriceCategory(catId: number): void {
    let mode = 'hide';
    if (this.filteredCatIds.indexOf('p' + catId) < 0) {
      mode = 'show';
    }

    if (mode === 'hide') {
      this.filteredCatIds.splice(this.filteredCatIds.indexOf('p' + catId), 1);
    } else if (mode === 'show') {
      this.filteredCatIds.push('p' + catId);
    }

    if (catId === 0) {
      this.filteredCatIds = [];
    }

    let filterString;

    if (this.filteredCatIds.length === 0) {
      for (const categoryId in this.seatmapOptions.priceCategories) {
        if (!this.seatmapOptions.priceCategories.hasOwnProperty(categoryId)) {
          continue;
        }
        this.filteredCatIds.push('p' + categoryId);
      }
      filterString = this.filteredCatIds.join(',');
      this.filteredCatIds = [];
    } else {
      filterString = this.filteredCatIds.join(',');
    }

    const filter = {
      priceCategories: filterString,
    };

    this.seatmapObject.seatmap('filter', filter);
    this.seatmapObject.scrollTo();
  }

  updateTicketHeight(input: ElementRef, height: string | number): void {
    input.nativeElement.height = height;
  }

  ngDoCheck(): void {
    setTimeout(() => {
      if (!this.isUpdating) {
        // * oldIdentifier is null at beginning.
        if (this.oldIdentifier !== null) {
          if (this.identifier !== this.oldIdentifier) {
            this.oldIdentifier = this.identifier;
            this.reloadSeatMap(this.identifier);
          }
        }
      }
    }, 2000);
  }

  /**
   * Reloads ticket data and seatmap
   *
   * @param identifier string
   * @returns void
   */
  reloadSeatMap(identifier: string): void {
    if (identifier.indexOf('777') === 0) {
      this.packageButtonActive = true;
      identifier = identifier.replace(/777/g, '-');
    } else {
      this.packageButtonActive = false;
      if (this.identifier.indexOf('777') !== 0) {
        this.detailService
          .loadDetailData(this.identifier)
          .subscribe((details) => {
            this.eventData = details;
            if (details?.headliner?.packages.length >= 1) {
              this.packageID = details.headliner.packages[0].package_id;
            }
            this.validatePackageButton();
          });
      }
    }
    this.ticketService.loadTicketData(identifier).subscribe((details) => {
      this.spinnerService.show('loadingSpinner');
      this.basketService.basketLoading.next(true);
      this.priceCategories = null;
      this.details = null;
      this.oldIdentifier = null;
      this.globalConfig = this.config.getConfigObject();
      if (details !== null) {
        this.oldIdentifier = this.identifier;
        this.details = details;
        if (this.details.tickets[0]) {
          this.priceCategories = {};
          for (const priceG of this.details.tickets) {
            if (!this.priceCategories[priceG.internalKey]) {
              this.priceCategories[priceG.internalKey] = {
                id: priceG.priceGroupId,
                title: priceG.categoryName,
                color: priceG.eventDateId.substring(0, 6),
                price: priceG.price,
                seatGroup: priceG.seatGroup,
                priceFormatted: priceG.price,
                priceGroups: [
                  {
                    id: priceG.priceGroupId,
                    title: priceG.priceGroupName,
                    price: priceG.price,
                    priceFormatted: priceG.price,
                    active: true,
                  },
                ],
                totalCount: '',
                connectedCount: '',
              };
            } else {
              this.priceCategories[priceG.internalKey].priceGroups.push({
                id: priceG.priceGroupId,
                title: priceG.priceGroupName,
                price: priceG.price,
                priceFormatted: priceG.price,
                active: true,
              });
            }
          }

          for (const priceG of this.details.tickets) {
            this.categoryData.push({
              id: priceG.eventDateId,
              color: priceG.color,
              eventId: priceG.eventId,
              categoryNumber: priceG.category,
              categoryName: priceG.categoryName,
              priceGroupName: priceG.priceGroupName,
              priceGroupId: priceG.priceGroupId,
              price: priceG.price,
              seatGroup: priceG.seatGroup,
              blockIdentifier: priceG.blockIdentifier,
              internalKey: priceG.internalKey,
            });
          }
        }
      }

      this.seatmapOptions = {
        evId: identifier,
        cType: 'inhouse',
        cId: '157',
        server: 'https://api.eventim.com',
        additionalRequestParams:
          '&a_mandant=001&a_kassierer=' +
          this.globalConfig.invoice.cashier +
          '&a_servleturl=' + environment.SEATMAP_URL,
        priceCategories: this.priceCategories,
      };
      // * Check if seatmapContainer is initialized otherwise 'create' a new seatmap
      if (this.seatmapContainer !== undefined && this.seatmapContainer !== null) {
        this.seatmapObject = $(this.seatmapContainer.nativeElement);
        this.seatmapObject.seatmap(
          $.extend(
            {
              zoomControl: {
                enabled: true,
                position: 'topright',
              },
              renderer: {
                seatRenderer: () => this.seatStyles,
                linkedBlockRenderer: () => this.linkedBlockStyles,
                seatGroupRenderer: () => this.seatGroupStyles,
              },
              skipHulls: true,
              seatViewInfos: true,
            },
            this.seatmapOptions
          )
        );
      } else {
        this.identifier = identifier;
        this.details = null;
        this.loadEventInformations();
      }

      this.basketService.basketLoading.next(false);
      this.spinnerService.hide('loadingSpinner');
    });
  }

  /**
   * Fake method preventing keyvalue pipe sorting price categories
   */
  unsorted() {}


}
