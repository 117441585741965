<section class="etsContentWrapper etsWrapper">
  <div class="checkout" [class.shippingMethodView]="step === 'shipping'" [class.paymentMethodView]="step === 'payment'" [class.finishView]="step === 'finish'">
    <ul class="steps clearfix" *ngIf="step !== 'finish' && step !== 'register'" >
      <li class="login" [class.active]="step === 'login' || step === 'password' || step === 'register'">
        <a *ngIf="!isGuestOrder" class="inactive"><q></q><span>{{ 'checkout.SignIn' | translate }}</span></a>
        <a *ngIf="isGuestOrder" [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-login'}]"><q></q><span>{{ 'checkout.SignIn' | translate }}</span></a>
      </li>
      <li *ngIf="stepInt < 1" class="shippingAddress" [class.active]="step === 'address'">
        <a *ngIf="!isGuestOrder"><q></q><span>{{ 'checkout.Address' | translate }}</span></a>
        <a *ngIf="isGuestOrder" [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-address'}]"><q></q><span>{{ 'checkout.Address' | translate }}</span></a>
      </li>
      <li *ngIf="stepInt >= 1" class="shippingAddress" [class.active]="step === 'address'">
        <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-address'}]"><q></q><span>{{ 'checkout.Address' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt < 2" class="shippingMethod" [class.active]="step === 'shipping'">
        <a><q></q><span>{{ 'checkout.ShippingMethod' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt >= 2" class="shippingMethod" [class.active]="step === 'shipping'">
        <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-shipping'}]"><q></q><span>{{ 'checkout.ShippingMethod' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt < 6 || basketService.basketSubjectValue.totalPrice <= 0" class="payment" [class.active]="step === 'payment'">
        <a><q></q><span>{{ 'checkout.PaymentMethod' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt >= 6 && basketService.basketSubjectValue.totalPrice > 0" class="payment" [class.active]="step === 'payment'">
        <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-payment'}]"><q></q><span>{{ 'checkout.PaymentMethod' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt < 7" class="confirm" [class.active]="step === 'confirm'">
        <a><q></q><span>{{ 'checkout.Completeorder' | translate }}</span>
        </a></li>
      <li *ngIf="stepInt >= 7" class="confirm" [class.active]="step === 'confirm'">
        <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-confirm'}]"><q></q><span>{{ 'checkout.Completeorder' | translate }}</span>
        </a></li>
    </ul>

    <ng-container *ngIf="step !== 'finish' && step !== 'failure' && step !== 'password' && step !== 'passwordrecovery'">

      <div class="etsCaptionWrapper">
        @if (boschShop) {
          <h1 id="checkoutCaption" class="etsSiteCaption" *ngIf="step!=='register'">Gastbestellung</h1>
        } @else {
          <h1 id="checkoutCaption" class="etsSiteCaption" *ngIf="step!=='register'">{{ 'checkout.yourOrder' | translate }}</h1>
        }
        <h1 class="etsSiteCaption" *ngIf="step==='register'">{{ 'checkout.salutation' | translate }}</h1>
      </div>

      <div class="twoBlocks m-t-40">
        <div *ngIf="step==='promotionCode'" class="box">
          <form method="post">
            <h3>{{ 'couponCodes.headline' | translate }}</h3>
            <p>{{ 'couponCodes.couponInfo' | translate }}</p>
            <p *ngIf="couponService.etsCouponSettings === undefined || couponService.etsCouponSettings === null || couponService.etsCouponSettings === '' ">{{ 'couponCodes.couponInfo2' | translate }}</p>
            <div *ngIf="couponService.etsCouponSettings !== undefined || couponService.etsCouponSettings !== null || couponService.etsCouponSettings !== ''" [innerHTML]="couponService.etsCouponSettings.description">

            </div>
            <input [(ngModel)]="promotionCode" [ngModelOptions]="{standalone: true}" type="text" placeholder="{{ 'couponCodes.placholderInput' | translate }}" (keyup.enter)="checkPromotionCode()">
            <a class="etsBtn submitButton" (click)="checkPromotionCode()">{{ 'couponCodes.btnConfirm' | translate }}</a>
          </form>
        </div>
        <div *ngIf="step==='login'" class="box login">
          <h3>{{ 'checkout.allreadyCustomer' | translate }}</h3>
          <form method="post">
            <input [(ngModel)]="loginUser" [ngModelOptions]="{standalone: true}" type="email" name="user" value="" placeholder="E-Mail-Adresse" (keyup.enter)="doLogin()">
            <input [(ngModel)]="loginPassword" [ngModelOptions]="{standalone: true}" type="password" name="pass" placeholder="Passwort" (keyup.enter)="doLogin()">
            <a class="etsBtn submitButton login" (click)="doLogin()">{{ 'checkout.SignIn' | translate }}</a>
            <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-password'}]" class="forgotPassword link">{{ 'checkout.forgotPassword' | translate }}</a><br>
            <a *ngIf="guestOrdersEnabled && !isRegularUser && !isGuestOrder" style="margin-top: 2rem;" class="etsBtn submitButton login" (click)="doGuestLogin()">{{ 'checkout.guestOrder' | translate }}</a>
          </form>
          <div class="newAccount">
            <h3>{{'ets-menu-buttons.register.headline' | translate}}</h3>
            <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-register'}]" class="etsBtn">{{ 'checkout.register' | translate }}</a>
          </div>
        </div>
        <div *ngIf="step==='confirm'" class="box confirm" id="confirm">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <ngx-spinner name="checkoutConfirmLoading" [fullScreen]="true" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <div class="shippingAndPaymentInfo">
            <h3>{{ 'checkout.paymentAndShipping' | translate }}</h3>

            <div class="flexTable simpleTable infoTable">
              <div class="etsContent clearfix">
                <div class="line">
                  <div class="label">{{ 'checkout.ShippingMethod' | translate }}:</div>
                  <div class="value">
                    {{ checkoutService.getShippingSubjectValue.title }}
                    <div class="description">
                      {{ checkoutService.getShippingSubjectValue.description }}
                    </div>
                  </div>
                  <div class="actions">
                    <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-shipping'}]" class="edit actionButton"><div>D</div></a>
                  </div>
                </div>
                <div *ngIf="basketService.basketSubjectValue.totalPrice > 0" class="line">
                  <div class="label">{{ 'checkout.PaymentMethod' | translate }}:</div>
                  <div class="value">{{ checkoutService.getPaymentSubjectValue.name }}</div>
                  <div class="actions">
                    <a [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-payment'}]" class="edit actionButton"><div>D</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="step==='address'" class="box shippingAddress">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <h3 *ngIf="isCustomer && !isGuestOrder">{{ 'checkout.currentAddress' | translate }}</h3>
          <div *ngIf="isCustomer && !isGuestOrder">
            <strong>{{ 'checkout.customerID' | translate }}:</strong> {{ loginService.loginDataValue.id }}<br><br>
          </div>
          <div>
            @if (boschShop) {
              <p>Lieferadresse zu Ihrer Gastbestellung</p>
            } @else {
              <p *ngIf="!isCustomer">{{ 'checkout.checkoutFormularGuestInfo' | translate }}</p>
            }
          </div>
          <form class="keepPlaceholder checkoutForm" (ngSubmit)="updateAddress($event)" autocomplete="on">
            <div class="line field-salutation" [ngClass]="{'error': !!registerErrors.salutation}">
              <span class="placeHolder active" style="opacity: 1;">{{ 'checkout.formSalutation' | translate }}*</span>
              <select name="salutation" class="input active" required="" style="padding-top: 13px;" autocomplete="sex">
                <option value="" disabled="" [selected]="loginService.isGuest && loginService.loginDataValue.salutation === '0'">{{ 'checkout.formSalutation' | translate }}*</option>
                <option *ngFor="let item of etsRegisterData.salutations" value="{{item.key}}" [selected]="item.key==loginService.loginDataValue.salutation">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.salutation">{{registerErrors.salutation}}</span>
            </div>
            <div class="line field-title">
              <span class="placeHolder" style="opacity: 0;">{{ 'checkout.formTitle' | translate }}</span>
              <input type="text" autocomplete="disallow" name="title" value="{{loginService.loginDataValue.title}}" class="input" autocomplete="honorific-prefix" placeholder="{{ 'checkout.formTitle' | translate }}" style="padding-top: 0;" (keyup.enter)="updateAddress($event)">
            </div>
            <div class="line field-firstname" [ngClass]="{'error': !!registerErrors.forename}">
              <span class="placeHolder active" style="opacity: 1;">{{ 'checkout.formName' | translate }}*</span><input type="text" name="firstname" autocomplete="name" value="{{loginService.loginDataValue.forename}}" class="input active" placeholder="{{ 'checkout.formName' | translate }}*" required="" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
              <span class="errorMessage" *ngIf="!!registerErrors.forename">{{registerErrors.forename}}</span>
            </div>
            <div class="line field-lastname" [ngClass]="{'error': !!registerErrors.surname}">
              <span class="placeHolder active" style="opacity: 1;">{{ 'checkout.formSurname' | translate }}*</span><input type="text" name="lastname" autocomplete="family-name" value="{{loginService.loginDataValue.surname}}" class="input active" placeholder="{{ 'checkout.formSurname' | translate }}*" required="" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
              <span class="errorMessage" *ngIf="!!registerErrors.surname">{{registerErrors.surname}}</span>
            </div>
            <div *ngIf="addInfoDesc !== ''" class="line field-nameAddition" [ngClass]="{'error' : !!registerErrors.additionalInfo}">
              <!-- ! All Inputs with Max-Length are for shop 333678 => REMOVE ASAP!!! -->
              <span *ngIf="addInfoReq" class="placeHolder active" style="opacity: 1;">{{addInfoDesc}}*</span><input *ngIf="addInfoReq && !addInfoMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
              <input *ngIf="addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
              <span *ngIf="!addInfoReq" class="placeHolder active" style="opacity: 0;">{{addInfoDesc}}</span><input *ngIf="!addInfoReq && !addInfoMaxLength"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}" style="padding-top: 13px;">
              <input *ngIf="!addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
            </div>
            <div *ngIf="addInfoDesc === ''" class="line field-nameAddition" [ngClass]="{'error' : !!registerErrors.additionalInfo}">
              <!-- ! All Inputs with Max-Length are for shop 333678 => REMOVE ASAP!!! -->
              <span *ngIf="addInfoReq" class="placeHolder active" style="opacity: 1;">{{'checkout.formAdditionalInformation' | translate}}*</span><input *ngIf="addInfoReq && !addInfoMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input active" placeholder="Zusatzinformationen*" style="padding-top: 0px;">
              <input *ngIf="addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
              <span *ngIf="!addInfoReq" class="placeHolder active">{{'checkout.formAdditionalInformation' | translate}}</span><input *ngIf="!addInfoReq && !addInfoMaxLength"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input active" placeholder="Zusatzinformationen" style="padding-top: 0px;">
              <input *ngIf="!addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
            </div>
            @if (!boschShop) {
              <div class="line field-streetName" [ngClass]="{'error': !!registerErrors.street}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.Street' | translate }}*</span><input type="text" name="streetName" [value]="loginService.loginDataValue.street" class="input" autocomplete="address-line2" placeholder="{{'userDataForm.Street' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.street">{{registerErrors.street}}</span>
              </div>

              <div class="line field-streetNumber" [ngClass]="{'error': !!registerErrors.houseNumber}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.HouseNo' | translate }}*</span><input type="text" name="streetNumber" [value]="loginService.loginDataValue.houseNumber" class="input" placeholder="{{'userDataForm.HouseNo' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.houseNumber">{{registerErrors.houseNumber}}</span>
              </div>
              <div class="line field-zip" [ngClass]="{'error': !!registerErrors.zipCode}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.ZIPCode' | translate }}*</span><input type="text" name="zip" [value]="loginService.loginDataValue.zipCode" autocomplete="postal-code" class="input" placeholder="{{'userDataForm.ZIPCode' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.zipCode">{{registerErrors.zipCode}}</span>
              </div>
              <div class="line field-city"[ngClass]="{'error': !!registerErrors.city}">
                <span class="placeHolder" style="opacity: 0;">{{'registration.City' | translate }}*</span><input type="text" name="city" [value]="loginService.loginDataValue.city" class="input"  placeholder="{{'userDataForm.City' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.city">{{registerErrors.city}}</span>
              </div>
              <div class="line field-country" [ngClass]="{'error': !!registerErrors.country}">
                <span class="placeHolder active" style="opacity: 1;">{{'registration.Country' | translate }}*</span><select name="country" class="input active" required="" style="padding-top: 13px;">
                <option value="" disabled="" selected>{{ 'registration.Country' | translate }}*</option>
                <option *ngFor="let item of etsRegisterData.county" value="{{item.key}}" [selected]="item.key == loginService.loginDataValue.countryInternalKey">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.country">{{registerErrors.country}}</span>
              </div>
            } @else {
              <!-- ! Special Case for Bosch shops -->
              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.company}">
                <span class="placeHolder" style="opacity: 1;">Rechtseinheit (z.B. Robert Bosch GmbH, Robert Bosch Power Tools GmbH ...)*</span><input type="text" name="company" [value]="loginService.loginDataValue.name4.split('/')[0]?.trim() ?? ''" class="input" placeholder="Rechtseinheit (z.B. Robert Bosch GmbH, Robert Bosch Power Tools GmbH, ETAS GmbH, ...)*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.company">{{registerErrors.company}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.department}">
                <span class="placeHolder" style="opacity: 1;">Abteilungskürzel*</span><input type="text" name="department" [value]="loginService.loginDataValue.name4.split('/')[1]?.trim() ?? ''" class="input" placeholder="Abteilungskürzel*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.department">{{registerErrors.department}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.street}">
                <span class="placeHolder" style="opacity: 1;">Straße der Firma*</span><input type="text" name="streetName" [value]="loginService.loginDataValue.street" class="input" placeholder="Straße der Firma*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.street">{{registerErrors.street}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.houseNumber}">
                <span class="placeHolder" style="opacity: 1;">Hausnummer der Firma*</span><input type="text" name="streetNumber" [value]="loginService.loginDataValue.houseNumber" class="input" placeholder="Hausnummer der Firma*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.houseNumber">{{registerErrors.houseNumber}}</span>
              </div>
              <div class="line field-zip" [ngClass]="{'error': !!registerErrors.zipCode}">
                <span class="placeHolder" style="opacity: 1;">PLZ der Firma*</span><input type="text" name="zip" [value]="loginService.loginDataValue.zipCode" class="input" placeholder="PLZ der Firma*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.zipCode">{{registerErrors.zipCode}}</span>
              </div>
              <div class="line field-city"[ngClass]="{'error': !!registerErrors.city}">
                <span class="placeHolder" style="opacity: 1;">Ort der Firma*</span><input type="text" name="city" [value]="loginService.loginDataValue.city" class="input" placeholder="Ort der Firma*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.city">{{registerErrors.city}}</span>
              </div>
              <div class="line field-country" [ngClass]="{'error': !!registerErrors.country}">
                <span class="placeHolder active" style="opacity: 1;">Land der Firma*</span><select name="country" class="input active" required="" style="padding-top: 13px;">
                <option value="" disabled="" selected>Land der Firma*</option>
                <option *ngFor="let item of etsRegisterData.county" value="{{item.key}}" [selected]="item.key == loginService.loginDataValue.countryInternalKey">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.country">{{registerErrors.country}}</span>
              </div>
            }
            <div class="line field-areaCode">
              <span class="placeHolder" style="opacity: 1;">{{ 'checkout.formAreaCode' | translate}}</span><input type="text" autocomplete="tel-area-code" name="areaCode" value="{{loginService.loginDataValue.phoneAreaCode}}" class="input" placeholder="{{ 'checkout.formAreaCode' | translate}}" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
            </div>
            <div class="line field-phoneNumber">
              <span class="placeHolder" style="opacity: 1;">{{ 'checkout.formPhonenumber' | translate }}</span><input type="text" name="phoneNumber" autocomplete="tel-local" value="{{loginService.loginDataValue.phoneNumber}}" class="input" placeholder="{{ 'checkout.formPhonenumber' | translate }}" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
            </div>
            @if (boschShop) {
              <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddress}">
                <span class="placeHolder active" style="opacity: 1;">E-Mail, vorzugsweise geschäftlich*</span><input type="email" name="email" value="{{loginService.loginDataValue.emailAddress}}" class="input active" placeholder="E-Mail, vorzugsweise geschäftlich*" required="" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
                <span class="errorMessage" *ngIf="!!registerErrors.emailAddress">{{registerErrors.emailAddress}}</span>
              </div>
            } @else {
              <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddress}">
                <span class="placeHolder active" style="opacity: 1;">{{ 'checkout.formEmail' | translate }}*</span>
                <input type="email" name="email"
                  value="{{loginService.loginDataValue.emailAddress}}"
                  class="input active" placeholder="{{ 'checkout.formEmail' | translate }}*"
                  required=""
                  style="padding-top: 13px;" [style]="isGuestOrder ? '' : 'text-transform: unset;'"
                  (keyup.enter)="updateAddress($event)">
                <span class="errorMessage" *ngIf="!!registerErrors.emailAddress">{{registerErrors.emailAddress}}</span>
              </div>
            }

            @if (isGuestOrder) {
              @if (boschShop) {
                <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddressRepeat}">
                  <span class="placeHolder active" style="opacity: 1;">{{ 'registration.EmailConfirmation' | translate }}*</span><input type="email" name="emailRepeat" value="{{loginService.loginDataValue.emailAddress}}" class="input active" placeholder="{{ 'registration.EmailConfirmation' | translate }}*" required="" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
                  <span class="errorMessage" *ngIf="!!registerErrors.emailAddressRepeat">{{registerErrors.emailAddressRepeat}}</span>
                </div>
              } @else {
                <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddressRepeat}">
                  <span class="placeHolder active" style="opacity: 1;">{{ 'registration.EmailConfirmation' | translate }}*</span><input type="email" name="emailRepeat" value="{{loginService.loginDataValue.emailAddress}}" class="input active" placeholder="{{ 'registration.EmailConfirmation' | translate }}*" required="" style="padding-top: 13px;" (keyup.enter)="updateAddress($event)">
                  <span class="errorMessage" *ngIf="!!registerErrors.emailAddressRepeat">{{registerErrors.emailAddressRepeat}}</span>
                </div>
              }
            }
            <div *ngIf="isRegularUser && !isGuestOrder" class="passwordChangeButton">
              <a (click)="togglePasswordForm()"><br><h3>{{ 'checkout.pwChangeText' | translate }}</h3></a>
            </div>
            <div *ngIf="showPasswordForm">
              <div class="line field-passwordOld">
                <span class="placeHolder" style="opacity: 0;">{{ 'checkout.pwCurrentPassword' | translate }}</span><input [type]="(addressShowOldPassword) ? 'text': 'password'" autocomplete="new-passwordOld" data-form-name="passwordOld" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{ 'checkout.pwCurrentPassword' | translate }}" name="passwordOld" style="padding-top: 0px;" (keyup.enter)="updateAddress($event)"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('addressPasswordOld')" class="visibilityToggler"></span>
              </div>
              <div class="line field-password" [ngClass]="{'error': !!registerErrors.password}">
                <span class="placeHolder" style="opacity: 0;">{{ 'checkout.pwNewPassword' | translate }}</span><input [type]="(addressShowPassword) ? 'text': 'password'" autocomplete="new-password" data-form-name="password" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{ 'checkout.pwNewPassword' | translate }}" name="password" style="padding-top: 0px;"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('addressPassword')" class="visibilityToggler" (keyup.enter)="updateAddress($event)"></span>
                <span class="errorMessage" *ngIf="!!registerErrors.password">{{registerErrors.password}}</span>
              </div>
              <div class="line field-passwordRepeat" [ngClass]="{'error': !!registerErrors.passwordRepeat}">
                <span class="placeHolder" style="opacity: 0;">{{ 'checkout.pwNewPasswordConfirm' | translate}}</span><input [type]="(addressShowPasswordConf) ? 'text': 'password'" autocomplete="new-passwordRepeat" data-form-name="passwordRepeat" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{ 'checkout.pwNewPasswordConfirm' | translate}}" name="passwordRepeat" style="padding-top: 0px;" (keyup.enter)="updateAddress($event)"><span title="{{ 'general.password.showPassword' | translate }}" class="visibilityToggler" (click)="showPassword('addressPasswordConf')"></span>
                <span class="errorMessage" *ngIf="!!registerErrors.passwordRepeat">{{registerErrors.passwordRepeat}}</span>
              </div>
            </div>
            <input [ngClass]="{'etsBtn': true, 'submitButton': true, 'inactive': basketService.basketSubjectValue.items && basketService.basketSubjectValue.items.length === 0}" type='submit' value="{{'checkout.BtnContinue' | translate}}" [disabled]="basketService.basketSubjectValue.items && basketService.basketSubjectValue.items.length === 0">
          </form>
        </div>
        <div *ngIf="step==='personalisation'" class="box ticketPersonalization">
          <h3>{{ 'checkout.ticketPersonalization.Headline' | translate }}</h3>
          <form>
            <div class="line personalization clearfix">
              <h3 class="topSpace"></h3>
              <div class="miniInfo">{{ 'checkout.ticketPersonalization.Info' | translate }}</div>

              <ng-container *ngFor="let tickets of personalisationData;let ind = index;">
                <div class="line bold" style="margin-bottom: 2rem; margin-top: 2rem;">{{tickets.titleOfEvent}} - {{ 'checkout.ticketPersonalization.Visistor' | translate }} {{tickets.visitorNumber}}</div>
                <div class="line field-firstnames">
                  <input type="text" [(ngModel)]="personalisationData[ind].data.firstnames" [ngModelOptions]="{standalone: true}" class="input" placeholder="{{'checkout.ticketPersonalization.firstname'| translate}}*" required="" (keyup.enter)="setPersonalisation()">
                </div>
                <div class="line field-lastnames">
                  <input type="text" [(ngModel)]="personalisationData[ind].data.lastnames" [ngModelOptions]="{standalone: true}" class="input" placeholder="{{'checkout.ticketPersonalization.surname'| translate}}*" required="" (keyup.enter)="setPersonalisation()">
                </div>
                <div class="line field-birthdays">
                  <input type="hidden" name="birthdays[]" value="21.06.1980">
                </div>
                <div class="line field-eventNos">
                  <input type="hidden" [(ngModel)]="personalisationData[ind].data.eventNos" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="line field-seatNos">
                  <input type="hidden" [(ngModel)]="personalisationData[ind].data.seatNos" [ngModelOptions]="{standalone: true}">
                </div>
              </ng-container>
            </div>
            <a class="etsBtn submitButton" (click)="setPersonalisation()">{{ 'checkout.ticketPersonalization.Continue' | translate }}</a>
          </form>
        </div>
        <div *ngIf="step==='shipping'" class="box shippingMethod">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <h3>{{ 'checkout.ticketShipping.Headline' | translate }}</h3>

          <form method="post">
            <ul class="etsAccordion option shippingMethod">
              <li *ngFor="let shippingOptions of checkoutService.getShippingDataSubjectValue.shippingMethods" [class.active]="shippingOptions.id === shippingMethod">
                <div class="etsTitle clearfix" (click)="accordionClick($event, shippingOptions)">
                  <div class="etsLeft">
                    {{ shippingOptions.title }}
                  </div>
                  <div class="etsRight clearfix">
                    <span class="price small">
                      {{'general.plus' | translate}} {{ shippingOptions.pricePlain | currency }} €
                    </span>
                    <div *ngIf="shippingOptions.description !== ''">
                      <a (click)="showShippingInfo($event, shippingOptions)" class="infoBtn actionButton"></a>
                    </div>
                  </div>
                </div>
                <div class="etsContent">
                  {{ shippingOptions.description }}
                </div>
              </li>
            </ul>
            <a class="etsBtn submitButton" [class.inactive]="shippingMethod===0" (click)="setShippingMethod()">{{ 'checkout.ticketShipping.Continue' | translate }}</a>
          </form>
        </div>
        <div *ngIf="step==='payment'" class="box paymentMethod">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <h3>{{ 'checkout.ticketPayment.Headline' | translate }}</h3>

          <form method="post">
            <ul #paymentList class="etsAccordion option paymentMethod">
              <li *ngFor="let paymentOptions of checkoutService.paymentDataSubject.value.paymentMethods; let last = last" [class.active]="paymentMethod === paymentOptions.id" [class.disabled]="paymentOptions.id === 'coupon' && voucherCodeRedeemed">
                <div class="etsTitle clearfix image" (click)="accordionPaymentClick($event, paymentOptions.id)">
                  <div class="image" [ngStyle]="{'background-image': 'url(' + paymentOptions.imageUrl + ')'}"></div>
                  <div class="name">{{paymentOptions.name}}</div>
                  <span *ngIf="last">{{ renderSelectedPaymentMethod() }}</span>
                </div>
                <div id="paymentContent" class="etsContent">
                  <div class="sepa" *ngIf="paymentOptions.id === 'sepa'">
                    <div class="line">
                      {{'checkout.ticketPayment.SepaIbanText' | translate}}
                    </div>
                    <div class="line">
                      <input type="text" [(ngModel)]="iban" [ngModelOptions]="{standalone: true}" (keyup.enter)="setPaymentMethod()">
                    </div>
                    <div class="bic line closed" style="height: 0px;">
                      <div class="line">
                        {{ 'checkout.ticketPayment.SepaBicText' | translate }}
                      </div>
                      <div class="line">
                        <input type="text" [(ngModel)]="bic" [ngModelOptions]="{standalone: true}">
                      </div>
                    </div>
                  </div>
                  <div id="paymentCoupon" class="coupon" *ngIf="paymentOptions.id === 'coupon'">
                    <div class="line">
                      {{ 'checkout.ticketPayment.CouponText1' | translate }}                                            <br>
                      {{ 'checkout.ticketPayment.CouponText2' | translate }}
                    </div>
                    <div class="line">
                      <input type="text" [(ngModel)]="couponCode" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <a class="etsBtn submitButton" [class.inactive]="paymentMethod === ''" (click)="setPaymentMethod()">{{ 'checkout.ticketPayment.Continue' | translate }}</a>
          </form>
        </div>
        <div *ngIf="step==='register'" class="box register">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <h3>{{ 'registration.Headline' | translate }}</h3>
          <form class="keepPlaceholder checkoutForm" (ngSubmit)="registerAddress($event)">
            <div class="line field-salutation" [ngClass]="{'error': !!registerErrors.salutation}">
              <span class="placeHolder" style="opacity: 0;">{{ 'registration.Salutation' | translate }}*</span>
              <select name="salutation" class="input active" required="" style="padding-top: 13px;">
              <option value="" disabled="" selected>Anrede*</option>
              <option *ngFor="let item of etsRegisterData.salutations" value="{{item.key}}" [selected]="item.key == loginService.loginDataValue.salutation">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.salutation">{{registerErrors.salutation}}</span>
            </div>
            <div class="line field-title">
              <span class="placeHolder" style="opacity: 0;">{{ 'registration.Title' | translate }}</span><input type="text" autocomplete="honorific-prefix" name="title" value="" class="input" placeholder="Titel" style="padding-top: 0px;">
            </div>
            <div class="line field-firstname" [ngClass]="{'error': !!registerErrors.forename}">
              <span class="placeHolder" style="opacity: 0;">{{ 'registration.Name' | translate }}*</span><input type="text" name="firstname" value="" autocomplete="name" class="input" placeholder="{{'userDataForm.Name' | translate}}*" required="" style="padding-top: 0px;">
              <span class="errorMessage" *ngIf="!!registerErrors.forename">{{registerErrors.forename}}</span>
            </div>
            <div class="line field-lastname" [ngClass]="{'error': !!registerErrors.surname}">
              <span class="placeHolder" style="opacity: 0;">{{ 'registration.Surname' | translate }}*</span><input type="text" name="lastname" value="" autocomplete="family-name" class="input" placeholder="{{'userDataForm.Surname' | translate}}*" required="" style="padding-top: 0px;">
              <span class="errorMessage" *ngIf="!!registerErrors.surname">{{registerErrors.surname}}</span>
            </div>
            <div *ngIf="addInfoDesc !== ''" class="line field-nameAddition" [ngClass]="{'error' : !!registerErrors.additionalInfo}">
              <!-- ! All Inputs with Max-Length are for shop 333678 => REMOVE ASAP!!! -->
              <span *ngIf="addInfoReq" class="placeHolder active" style="opacity: 1;">{{addInfoDesc}}</span><input *ngIf="addInfoReq && !addInfoMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
              <input *ngIf="addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
              <span *ngIf="!addInfoReq" class="placeHolder active" style="opacity: 0;">{{addInfoDesc}}</span><input *ngIf="!addInfoReq && !addInfoMaxLength"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}" style="padding-top: 13px;">
              <input *ngIf="!addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
            </div>
            <div *ngIf="addInfoDesc === ''" class="line field-nameAddition" [ngClass]="{'error' : !!registerErrors.additionalInfo}">
              <!-- ! All Inputs with Max-Length are for shop 333678 => REMOVE ASAP!!! -->
              <span *ngIf="addInfoReq" class="placeHolder active" style="opacity: 1;">{{'checkout.formAdditionalInformation' | translate}}*</span><input *ngIf="addInfoReq && !addInfoMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input active" placeholder="Zusatzinformationen*" style="padding-top: 13px;">
              <input *ngIf="addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
              <span *ngIf="!addInfoReq" class="placeHolder active">{{'checkout.formAdditionalInformation' | translate}}</span><input *ngIf="!addInfoReq && !addInfoMaxLength"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input active" placeholder="Zusatzinformationen" style="padding-top: 13px;">
              <input *ngIf="!addInfoReq && addInfoMaxLength" maxlength="8"  type="text" autocomplete="disallow" name="nameAddition" value="{{loginService.loginDataValue.nameSuffix}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 13px;">
            </div>

            @if (!boschShop) {
              <div class="line field-streetName" [ngClass]="{'error': !!registerErrors.street}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.Street' | translate }}*</span><input type="text" name="streetName" value="" class="input" placeholder="{{'userDataForm.Street' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.street">{{registerErrors.street}}</span>
              </div>

              <div class="line field-streetNumber" [ngClass]="{'error': !!registerErrors.houseNumber}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.HouseNo' | translate }}*</span><input type="text" name="streetNumber" value="" class="input" placeholder="{{'userDataForm.HouseNo' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.houseNumber">{{registerErrors.houseNumber}}</span>
              </div>
              <div class="line field-zip" [ngClass]="{'error': !!registerErrors.zipCode}">
                <span class="placeHolder" style="opacity: 0;">{{ 'registration.ZIPCode' | translate }}*</span><input type="text" name="zip" autocomplete="postal-code" value="" class="input" placeholder="{{'userDataForm.ZIPCode' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.zipCode">{{registerErrors.zipCode}}</span>
              </div>
              <div class="line field-city"[ngClass]="{'error': !!registerErrors.city}">
                <span class="placeHolder" style="opacity: 0;">{{'registration.City' | translate }}*</span><input type="text" name="city" value="" class="input" placeholder="{{'userDataForm.City' | translate}}*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.city">{{registerErrors.city}}</span>
              </div>
              <div class="line field-country" [ngClass]="{'error': !!registerErrors.country}">
                <span class="placeHolder active" style="opacity: 1;">{{'registration.Country' | translate }}*</span><select name="country" class="input active" required="" style="padding-top: 13px;" autocomplete="country">
                <option value="" disabled="" selected>{{ 'registration.Country' | translate }}*</option>
                <option *ngFor="let item of etsRegisterData.county" value="{{item.key}}" [selected]="item.key == loginService.loginDataValue.countryInternalKey">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.country">{{registerErrors.country}}</span>
              </div>
            } @else {
              <!-- ! Special Case for Bosch shops -->
              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.company}">
                <span class="placeHolder" style="opacity: 1;">Rechtseinheit (z.B. Robert Bosch GmbH, Robert Bosch Power Tools GmbH ...)*</span><input type="text" name="company" value="" class="input" placeholder="Rechtseinheit (z.B. Robert Bosch GmbH, Robert Bosch Power Tools GmbH, ETAS GmbH)*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.company">{{registerErrors.company}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.department}">
                <span class="placeHolder" style="opacity: 1;">Abteilungskürzel*</span><input type="text" name="department" value="" class="input" placeholder="Abteilungskürzel*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.department">{{registerErrors.department}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.street}">
                <span class="placeHolder" style="opacity: 1;">Straße der Firma*</span><input type="text" name="streetName" value="" class="input" placeholder="Straße der Firma*" required="" style="padding-top: 13px;">
                <span class="errorMessage" *ngIf="!!registerErrors.street">{{registerErrors.street}}</span>
              </div>

              <div class="line field-nameAddition" [ngClass]="{'error': !!registerErrors.houseNumber}">
                <span class="placeHolder" style="opacity: 0;">Hausnummer der Firma*</span><input type="text" name="streetNumber" value="" class="input" placeholder="Hausnummer der Firma*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.houseNumber">{{registerErrors.houseNumber}}</span>
              </div>
              <div class="line field-zip" [ngClass]="{'error': !!registerErrors.zipCode}">
                <span class="placeHolder" style="opacity: 0;">PLZ der Firma*</span><input type="text" name="zip" value="" class="input" placeholder="PLZ der Firma*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.zipCode">{{registerErrors.zipCode}}</span>
              </div>
              <div class="line field-city"[ngClass]="{'error': !!registerErrors.city}">
                <span class="placeHolder" style="opacity: 0;">Ort der Firma*</span><input type="text" name="city" value="" class="input" placeholder="Ort der Firma*" required="" style="padding-top: 0px;">
                <span class="errorMessage" *ngIf="!!registerErrors.city">{{registerErrors.city}}</span>
              </div>
              <div class="line field-country" [ngClass]="{'error': !!registerErrors.country}">
                <span class="placeHolder active" style="opacity: 1;">Land der Firma*</span><select name="country" class="input active" required="" style="padding-top: 13px;">
                <option value="" disabled="" selected>Land der Firma*</option>
                <option *ngFor="let item of etsRegisterData.county" value="{{item.key}}" [selected]="item.key == loginService.loginDataValue.countryInternalKey">{{item.value}}</option>
              </select>
              <span class="errorMessage" *ngIf="!!registerErrors.country">{{registerErrors.country}}</span>
              </div>
            }
            <div class="line field-areaCode">
              <span class="placeHolder" style="opacity: 1;">{{ 'registration.AreaCode' | translate }}</span><input type="text" autocomplete="tel-area-code" name="areaCode" value="" class="input" placeholder="{{'userDataForm.AreaCode' | translate}}" style="padding-top: 13px;">
            </div>
            <div class="line field-phoneNumber">
              <span class="placeHolder" style="opacity: 1;">{{ 'registration.Phonenumber' | translate }}</span><input type="text" name="phoneNumber" autocomplete="tel" value="" class="input" placeholder="{{'userDataForm.Phonenumber' | translate}}" style="padding-top: 13px;">
            </div>
            <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddress}">
              <span class="placeHolder" style="opacity: 0;">{{'registration.Email' | translate}}*</span><input type="email" name="email" value="" autocomplete="email" class="input" placeholder="{{'userDataForm.Email' | translate}}*" required="" style="padding-top: 0px;">
              <span class="errorMessage" *ngIf="!!registerErrors.emailAddress">{{registerErrors.emailAddress}}</span>
            </div>
            <div class="line field-emailRepeat error" [ngClass]="{'error': !!registerErrors.emailAddressRepeat}">
              <span class="placeHolder" style="opacity: 0;">{{ 'registration.EmailConfirmation' | translate }}*</span><input type="email" name="emailRepeat" value="" autocomplete="email" class="input" placeholder="{{'userDataForm.Email' | translate}}*" required="" style="padding-top: 0px;">
              <span class="errorMessage" *ngIf="!!registerErrors.emailAddressRepeat">{{registerErrors.emailAddressRepeat}}</span>
            </div>
            <div class="line field-password" [ngClass]="{'error': !!registerErrors.password}">
              <span class="placeHolder" style="opacity: 0;">{{'registration.Password' | translate }}*</span><input [type]="(registerShowPassword) ? 'text': 'password'" autocomplete="new-password" data-form-name="password" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{'registration.Password' | translate}}*" required="" name="password" style="padding-top: 0px;"  ><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('registerPassword')" class="visibilityToggler"></span>
              <span class="errorMessage" *ngIf="!!registerErrors.password">{{registerErrors.password}}</span>
            </div>
            <div class="line field-passwordRepeat" [ngClass]="{'error': !!registerErrors.passwordRepeat}">
              <span class="placeHolder" style="opacity: 0;">{{'registration.PasswordConfirmation' | translate }}*</span><input [type]="(registerShowPasswordConf) ? 'text': 'password'" autocomplete="new-passwordRepeat" data-form-name="passwordRepeat" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{'registration.PasswordConfirmation' | translate }}*" required="" name="passwordRepeat" style="padding-top: 0px;"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('registerPasswordConf')" class="visibilityToggler"  ></span>
              <span class="errorMessage" *ngIf="!!registerErrors.passwordRepeat">{{registerErrors.passwordRepeat}}</span>
            </div>
            <p>
              {{ 'registration.Information' | translate }}
            </p>

            <div>
              {{ 'registration.PrivacyInformation' | translate }}
            </div>
            <div class="info">
              {{ 'registration.Information2' | translate }}
            </div>
            <br>
            <input class="etsBtn submitButton" type='submit' value="{{ 'registration.Continue' | translate }}">
          </form>
        </div>
        <div class="box cart">
          <ngx-spinner [showSpinner]="basketService.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
            <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
          </ngx-spinner>
          <div class="cartInner">

            <h3>
              {{ 'checkout.basket.your_basket' | translate }}
            </h3>
            <div class="flexTable listTable cartTable">

              <div class="header cartNotEmpty">
                <div class="event">{{ 'checkout.basket.event' | translate }}</div>
                <div class="dateAndLocation">{{'checkout.basket.dateAndLocation' | translate }}</div>
                <div class="ticketInfo">{{ 'checkout.basket.ticketInfo' | translate }}</div>
                <div class="price">{{ 'checkout.basket.price' | translate }}</div>
              </div>
              <div class="etsContent clearfix">
                @for(item of basketService.basketSubjectValue.items; track index) {
                  <div class="line" [style]="!item.showActions ? 'border-top: unset;' : ''">
                    <div class="eventPicture">
                      <div class="image" [ngStyle]="{'background-image': 'url(' + item.imageUrl + ')'}"></div>
                      @if (item.showActions) {
                        <div class="actions">
                          <a *ngIf="!config.getConfigObject().detail.keepEventUrl || basketService.urlToEventId(item.eventId) === null" title="Bearbeiten" (click)="basketService.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId}]" class="edit actionButton handled"><div>D</div></a>
                          <a *ngIf="config.getConfigObject().detail.keepEventUrl && basketService.urlToEventId(item.eventId) !== null" title="Bearbeiten" (click)="basketService.deleteFromBasketWithRedirect(item.deleteParam, basketService.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                          <a title="Löschen" (click)="basketService.deleteFromBasket(item.deleteParam)" class="trash actionButton handled"><div>A</div></a>
                        </div>
                      }
                    </div>
                    <div class="eventName">
                      <a [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId}]" class="etsTitle">{{item.title}}</a>
                      <div class="subTitle">{{item.subTitle}}</div>
                    </div>
                    <div class="dateAndLocation">
                      <div *ngIf="!item.showEventTime && !item.hideDateFlag" class="date">{{item.date | date: 'EE, dd.MM.yyyy, H:mm':undefined:(config.getConfigObject().misc.languageCode !== 'de') ? 'en' : 'de'}} {{config.getConfigObject().misc.languageCode === 'de' ? 'Uhr' : ''}}</div>
                      <div *ngIf="item.showEventTime && !item.hideDateFlag" class="date">{{item.date.split(' ')[0] | date: 'EE, dd.MM.yyyy':undefined:(config.getConfigObject().misc.languageCode !== 'de') ? 'en' : 'de' }}{{item.date.split(',')[1]}}</div>
                      <div class="location">{{item.location}}</div>
                    </div>
                    <div class="ticketInfo">
                      <div class="quantity">{{'basket.amount' | translate}}: {{item.quantity}}</div>
                      <div class="details">{{item.seatInfo}}</div>
                      <div class="priceGroups">{{item.priceGroups}}</div>
                    </div>
                    <div class="price">{{ item.price | currency}} €</div>
                    @if (item.showActions) {
                      <div class="actions">
                        <a *ngIf="!config.getConfigObject().detail.keepEventUrl || basketService.urlToEventId(item.eventId) === null" title="Bearbeiten" (click)="basketService.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId.toString().replaceAll('-', '777')}]" class="edit actionButton handled"><div>D</div></a>
                        <a *ngIf="config.getConfigObject().detail.keepEventUrl && basketService.urlToEventId(item.eventId) !== null" title="Bearbeiten" (click)="basketService.deleteFromBasketWithRedirect(item.deleteParam, basketService.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                        <a title="Löschen" (click)="basketService.deleteFromBasket(item.deleteParam, true)" class="trash actionButton handled"><div>A</div></a>
                      </div>
                    }
                  </div>
                }

                <div class="line" *ngFor="let item of basketService.basketSubjectValue.chosenShipping">

                  <div class="shippingMethodLabel">
                    {{'checkout.basket.shippingFee' | translate }}
                  </div>
                  <div class="shippingMethodName alignRight">
                    {{ item.pricePlain | currency}} €
                  </div>
                </div>
                <div class="line" *ngFor="let item of basketService.basketSubjectValue.chosenShipping">

                  <div class="shippingMethodLabel">
                    {{ 'checkout.basket.shippingMethod' | translate}}
                  </div>
                  <div class="shippingMethodName alignRight">
                    {{item.title}}
                  </div>
                </div>
                <!-- ? Maybe needed in the future? -->
                <!-- <div *ngIf="config.getConfigObject().checkout.allowPromotioncodes" class="promotionCode">
                  <form (ngSubmit)="redeemPromoCode()" [formGroup]="promotionCodeForm" class="promotioncodeForm">
                    <div *ngIf="!basketService.basketSubjectValue.promotionCode" class="checkoutWrapper">
                      <input type="text" id="promotioncode" name="promotioncode" formControlName="promotioncode" placeholder="{{'basket.inputPromotionCodePlaceholder' | translate}}">
                      <button class="etsBtn" type="submit" [disabled]="promotionCodeForm.controls.promotioncode?.errors !== null">{{'basket.btnRedeemCode' | translate}}</button>
                    </div>
                    <div *ngIf="basketService.basketSubjectValue.promotionCode">
                      <div class="checkoutWrapper">
                        <input type="text" readonly [value]="'Rabattcode: ' + basketService.basketSubjectValue.promotionCode">
                        <button class="etsBtn" type="button" (click)="removePromoCode()">Rabattcode entfernen</button>
                      </div>
                    </div>
                    <div *ngIf=" promotionCodeForm.controls.promotioncode?.errors && promotionCodeForm.controls.promotioncode?.errors['minlength']">
                      <p>{{'basket.promotioncodeMinLength' | translate}}</p>
                    </div>
                    <div *ngIf="promotionCodeForm.controls.promotioncode?.errors && promotionCodeForm.controls.promotioncode?.errors['maxlength']">
                      <p>{{'basket.promotioncodeMaxLength' | translate}}</p>
                    </div>
                  </form>
                </div> -->
                <div class="line timeoutError cartNotEmpty" [class.off]="(basketService.timerStringValue !== '00:00')">{{ 'checkout.basket.reservationTimeout' | translate }}</div>

                <div class="line cartNotEmpty" *ngIf="basketService.basketSubjectValue.items && basketService.basketSubjectValue.items.length > 0">
                  <div class="amountCaption">

                      <strong>{{ 'checkout.basket.totalPrice' | translate }}</strong> <span class="shippingInfo"><span *ngIf="checkoutService.getShippingSubjectValue.id > 0">{{'general.including' | translate}} </span><span *ngIf="checkoutService.getShippingSubjectValue.id <= 0 || checkoutService.getShippingSubjectValue.id === undefined || checkoutService.getShippingSubjectValue.id === null">{{'general.plus' | translate}} </span> <app-ets-overlay title="{{ 'checkout.basket.shippingHeadline' | translate }}" linkTitle="{{'checkout.basket.shippingFee' | translate}}">
                    <div class="scroller">

                      <p class="description">{{'ticket.shippingInformations' | translate}}:<br>
                        {{'ticket.shippingInformations2' | translate}}:</p>
                      <ul class="optionsList">
                        <li class="clearfix" *ngFor="let shipping of basketService.basketSubjectValue.shipping">
                          <div class="info">
                            <h3>{{shipping.title}}</h3>
                          </div>
                          <div class="price">{{shipping.price}}</div>
                        </li>
                      </ul>
                    </div>
                    </app-ets-overlay></span>
                    <span title="Verbleibende Reservierungszeit" data-show-on-finish=".timeoutError" class="countdown">

                          <span class="label">{{'basket.remainingReservationTime' | translate }}:</span> <span class="time">{{basketService.timerStringValue}}</span>
                      </span>
                  </div>
                  <div class="price">
                    <div class="totalPrice">{{ basketService.basketSubjectValue.totalPrice | currency }} €</div>
                    <div class="shippingInfo">  <span *ngIf="checkoutService.getShippingSubjectValue.id > 0">{{'general.including' | translate}} </span><span *ngIf="checkoutService.getShippingSubjectValue.id <= 0 || checkoutService.getShippingSubjectValue.id === undefined || checkoutService.getShippingSubjectValue.id === null">{{'general.plus' | translate}} </span>  <app-ets-overlay title="Unsere Versandgebühren" linkTitle="{{'checkout.basket.shippingFee' | translate}}">
                      <div class="scroller">

                        <p class="description">{{'ticket.shippingInformations' | translate}}:<br>
                          {{'ticket.shippingInformations2' | translate}}:</p>
                        <ul class="optionsList">
                          <li class="clearfix" *ngFor="let shipping of basketService.basketSubjectValue.shipping">
                            <div class="info">
                              <h3>{{shipping.title}}</h3>
                            </div>
                            <div class="price">{{shipping.price}}</div>
                          </li>
                        </ul>
                      </div>
                    </app-ets-overlay></div>
                  </div>
                </div>
              </div>

              <div *ngIf="basketService.basketSubjectValue.items && basketService.basketSubjectValue.items.length === 0" class="etsContent cartEmpty">
                <div class="line">
                  <div class="notice">
                    {{ 'basket.empty' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box" *ngIf="step === 'confirm' && (paymentMethod !== 'sepa' || paymentMethod !== 'cash')">
        <p *ngIf="checkoutService.getCheckoutSubjectValue.url !== 'false'">{{'checkout.payment.Redirect' | translate }}</p>

      </div>
      <ng-container *ngIf="checkoutService.getCheckoutSubjectValue.url !== 'false'">
        <form *ngIf="step === 'confirm' && checkoutService.getCheckoutSubjectValue.postData" class="confirmBox telecashForm" method="post" (ngSubmit)="submitTelecash($event)" action="{{ checkoutService.getCheckoutSubjectValue.url }}" style="width: 100%;">
          <input *ngFor="let postData of checkoutService.getCheckoutSubjectValue.postData | keyvalue" type="hidden" name="{{postData.key}}" value="{{postData.value}}">
          <div class="line clearfix" *ngFor="let agb of checkoutService.getAGBSubjectValue">
            <div class="checkbox">
              <input type="hidden" id="termsAccepted" name="termsAccepted" [(ngModel)]="allowAGB" (change)="checkAGB($event)">
              <div class="checkboxPhantom" [class.active]="allowAGB" (click)="changeAGB($event)"></div>
            </div>

            <label for="termsAccepted" class="skiptranslate">
              {{'checkout.payment.TextTOS1' | translate }} <app-ets-overlay title="{{'ets-footer.TOC' | translate}}" linkTitle="{{ 'checkout.payment.TOSButton' | translate }}">
              <div class="scroller contentText etsContentWrapper" [innerHTML]="(config.getConfigObject().footer.textagb !== '') ? config.getConfigObject().footer.textagb : agb.content">
              </div>
            </app-ets-overlay> {{'checkout.payment.TextTOS2' | translate }}
            </label>
          </div>
          @if (boschShop) {
            <div class="line clearfix" style="margin-bottom: 20px; margin-top: 20px;">
              <div class="checkbox">
                <input type="hidden" id="meetandgreet" name="meetandgreet" [(ngModel)]="meetAndGreet">
                <div class="checkboxPhantom" [class.active]="meetAndGreet" (click)="meetAndGreet = !meetAndGreet"></div>
              </div>
              <label for="meetandgreet" (click)="meetAndGreet = !meetAndGreet">
                Ja, ich möchte gerne an der Verlosung für das Meet & Greet teilnehmen und mit etwas Glück die <strong style="text-transform: uppercase;">Fantastischen Vier</strong> Backstage kennenlernen.
              </label>
            </div>
            <div class="line clearfix photoConsent" style="margin-bottom: 20px; width: 100%;">

              <div class="photoConsent" style="width: 100%; display: flex;">
                <div style="display: flex;">
                  <div class="checkbox">
                    <input type="hidden" id="photoConsent" name="photoConsent" [(ngModel)]="photoConsent" required>
                    <div class="checkboxPhantom" [class.active]="photoConsent" (click)="photoConsent = !photoConsent"></div>
                  </div>
                  <label for="photoConsent" (click)="photoConsent = !photoConsent">
                    Einwilligung zu Foto- und Filmaufnahmen
                  </label>
                </div>
                <div class="infoText">
                  Die folgende Einwilligung bezieht sich auf alle Foto-, Video- und Audioaufnahmen sowie Foto-Upload (als Aufnahmen bezeichnet), die bei der folgenden Veranstaltung gemacht/aufgezeichnet werden:<br>
                  <strong>DIE FANTASTISCHEN VIER Mitarbeitenden-Konzert, 11. Juli 2024, Porsche-Arena, Mercedesstraße 69, 70372 Stuttgart.</strong><br>
                  Hinweis: Die Verwendung der Aufnahmen steht in direktem Zusammenhang mit der oben genannten Veranstaltung.
                  Ich willige ein, dass die Robert Bosch GmbH und die mit ihr verbundenen Unternehmen (§ 15 AktG; Bosch-Gruppe) die Aufnahmen zu internen Kommunikations-, Präsentations- und Dokumentationszwecken im Rahmen der Veranstaltungskommunikation weltweit veröffentlichen und verbreiten dürfen. Bitte lesen Sie die Einwilligung zu Foto- und Filmaufnahmen sorgfältig durch. Weitere Informationen finden Sie
                  <a href="https://www.easyticket.de/files/file-uploads/F4MAKonzert_2024_Datenschutz.pdf" target="_blank">hier</a>.
                </div>
              </div>

            </div>
          }

          <div class="requiredItemError line errorBox" *ngIf="showAGBError">{{'checkout.payment.TOSError' | translate }}</div>
          <input type="submit" value="{{ 'checkout.payment.orderNow' | translate }}" class="etsBtn confirmBtn mainButton submitButton" />
        </form>
      </ng-container>
      <ng-container *ngIf="checkoutService.getCheckoutSubjectValue.url === 'false'">
        <form *ngIf="step === 'confirm'" class="confirmBox telecashForm">
          <div class="line clearfix" *ngFor="let agb of checkoutService.getAGBSubjectValue">
            <div class="checkbox">
              <input type="hidden" id="termsAccepted" name="termsAccepted" [(ngModel)]="allowAGB" (change)="checkAGB($event)">
              <div class="checkboxPhantom" [class.active]="allowAGB" (click)="changeAGB($event)"></div>
            </div>

            <label for="termsAccepted" class="skiptranslate">
              {{ 'checkout.payment.TextTOS1' | translate }} <app-ets-overlay title="{{'ets-footer.TOC' | translate}}" linkTitle="{{ 'checkout.payment.TOSButton' | translate }}">
              <div class="scroller contentText etsContentWrapper" [innerHTML]="(config.getConfigObject().footer.textagb !== '') ? config.getConfigObject().footer.textagb : agb.content">
              </div>
            </app-ets-overlay> {{ 'checkout.payment.TextTOS2' | translate }}
            </label>
          </div>
          @if (boschShop) {
            <div class="line clearfix" style="margin-bottom: 20px; margin-top: 20px;">
              <div class="checkbox">
                <input type="hidden" id="meetandgreet" name="meetandgreet" [(ngModel)]="meetAndGreet">
                <div class="checkboxPhantom" [class.active]="meetAndGreet" (click)="meetAndGreet = !meetAndGreet"></div>
              </div>
              <label for="meetandgreet" (click)="meetAndGreet = !meetAndGreet">
                Ja, ich möchte gerne an der Verlosung für das Meet & Greet teilnehmen und mit etwas Glück die <strong>Fantastischen Vier</strong> Backstage kennenlernen.
              </label>
            </div>
            <div class="line clearfix photoConsent" style="margin-bottom: 20px; width: 100%;">
              <div class="photoConsent" style="width: 100%; display: flex;">
                <div style="display: flex;">
                  <div class="checkbox">
                    <input type="hidden" id="photoConsent" name="photoConsent" [(ngModel)]="photoConsent" required>
                    <div class="checkboxPhantom" [class.active]="photoConsent" (click)="photoConsent = !photoConsent"></div>
                  </div>
                  <label for="photoConsent" (click)="photoConsent = !photoConsent">
                    Einwilligung zu Foto- und Filmaufnahmen
                  </label>
                </div>
                <div class="infoText">
                  Die folgende Einwilligung bezieht sich auf alle Foto-, Video- und Audioaufnahmen sowie Foto-Upload (als Aufnahmen bezeichnet), die bei der folgenden Veranstaltung gemacht/aufgezeichnet werden:<br>
                  <strong>DIE FANTASTISCHEN VIER Mitarbeitenden-Konzert, 11. Juli 2024, Porsche-Arena, Mercedesstraße 69, 70372 Stuttgart.</strong><br>
                  Hinweis: Die Verwendung der Aufnahmen steht in direktem Zusammenhang mit der oben genannten Veranstaltung.
                  Ich willige ein, dass die Robert Bosch GmbH und die mit ihr verbundenen Unternehmen (§ 15 AktG; Bosch-Gruppe) die Aufnahmen zu internen Kommunikations-, Präsentations- und Dokumentationszwecken im Rahmen der Veranstaltungskommunikation weltweit veröffentlichen und verbreiten dürfen. Bitte lesen Sie die Einwilligung zu Foto- und Filmaufnahmen sorgfältig durch. Weitere Informationen finden Sie
                  <a href="https://www.easyticket.de/files/file-uploads/F4MAKonzert_2024_Datenschutz.pdf" target="_blank">hier</a>.
                </div>
              </div>

            </div>
          }
          <div class="requiredItemError line errorBox" *ngIf="showAGBError">{{'checkout.payment.TOSError' | translate }}</div>

          <input [disabled]="basketService.basketLoadingValue" type="submit" (click)="noTelecashPayment()" value="{{ 'checkout.payment.orderNow' | translate }}" class="etsBtn confirmBtn mainButton submitButton" />
        </form>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 'finish'">
      <ngx-spinner name="finishspinner" [fullScreen]="true" type="ball-clip-rotate" size="medium">

        <p class="loading spinnerText">{{ 'checkout.payment.verify' | translate }}</p>
      </ngx-spinner>
      <ng-container *ngIf="!loadingFinishInProgress">
        <ngx-spinner [showSpinner]="printAtHomeLoading" class="loading spinnerText" type="ball-clip-rotate" size="medium">
          <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
        </ngx-spinner>
        <div class="etsCaptionWrapper">
          <h1 class="etsSiteCaption" *ngIf="checkoutService.getPaymentSuccessTestValue">{{ 'checkout.order.Done' | translate }}</h1>
        </div>

        <div class="etsCaptionWrapper">
          <h1 class="etsSiteCaption" *ngIf="!checkoutService.getPaymentSuccessTestValue">{{ 'errorMessage.checkout.orderError' | translate }}</h1>
        </div>

        <div class="twoBlocks">
          <div class="box finish">
            <div class="block finishInfo">

              <h3 *ngIf="checkoutService.getPaymentSuccessTestValue">{{ 'checkout.order.Received' | translate }}</h3>
              <ng-container *ngIf="checkoutService.getPaymentSuccessTestValue && partnerSettings['use_final_page_html'] == 0">

                <p *ngIf="!isGuestOrder">{{ 'checkout.order.thanksforOrder' | translate }}</p>

                <p *ngIf="isGuestOrder">{{ 'checkout.order.thanksforOrderGuest' | translate }}</p>

                <p>{{ 'checkout.order.mailNotification' | translate }}<br>
                <p *ngIf="isCustomer">{{'checkout.order.profileNote' | translate}}</p><br>
                <button *ngIf="isPrintAtHomeOrder" class="etsBtn" (click)="downloadPrintAtHomeTicket()">Print&#64;Home Ticket herunterladen</button>
                <p>
                  <br>
                  {{ 'checkout.order.Salutation' | translate }}
                </p>
                <a class="etsBtn" (click)="router.resetToHome()">{{ 'checkout.basket.landingPage' | translate }}</a>
              </ng-container>
              <ng-container *ngIf="checkoutService.getPaymentSuccessTestValue && partnerSettings['use_final_page_html'] == 1">
                <div [innerHTML]="partnerSettings['final_page_html']"></div>
              </ng-container>

              <ng-container *ngIf="!checkoutService.getPaymentSuccessTestValue">
                <p>{{ 'errorMessage.checkout.orderErrorInfo' | translate}}</p>
                <a class="etsBtn" (click)="router.resetToHome()">{{ 'checkout.basket.landingPage' | translate }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 'failure'">
      <div class="etsCaptionWrapper">
        <h1 class="etsSiteCaption">{{ 'checkout.order.Error' | translate }}</h1>
      </div>
      <div class="m-t-40">
        {{ 'checkout.order.errorText' | translate }}
      </div>
      <a style="margin-top: 2rem;" class="etsBtn" (click)="router.resetToHome()">{{ 'basket.landingPage' | translate }}</a>
    </ng-container>

    <ng-container *ngIf="step==='password'">
      <div class="box forgotPassword">
        <h3>{{ 'forgotPassword.Headline' | translate }}</h3>
        <p>{{ 'forgotPassword.InfoText' | translate }}</p>
        <ngx-spinner [showSpinner]="passwordRecoveryLoading" [fullScreen]="false" type="ball-clip-rotate" size="medium">
          <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
        </ngx-spinner>
        <form method="post" (keyup.enter)="passwordForgot()">
          <input type="text" [(ngModel)]="passwordEmail" [ngModelOptions]="{standalone: true}" placeholder="E-Mail-Adresse">
          <a class="etsBtn login submitButton" (click)="passwordForgot()">{{ 'checkout.newPassword.requestPassword' | translate }}</a>
          <a class="back link" (click)="location.back()">{{ 'btnBack' | translate }}</a>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="step==='passwordrecovery'">
      <div class="box forgotPassword">
        <h3>{{ 'checkout.newPassword.Headline' | translate }}</h3>
        <p>{{'checkout.newPassword.InfoText' | translate }}</p>
        <ngx-spinner [showSpinner]="passwordRecoveryLoading" [fullScreen]="false" type="ball-clip-rotate" size="medium">
          <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
        </ngx-spinner>
        <form method="post" (keydown.enter)="passwordForgotReset()">
          <input type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="{{ 'checkout.newPassword.Password' | translate }}">
          <input type="password" [(ngModel)]="passwordRepeat" [ngModelOptions]="{standalone: true}" placeholder="{{ 'checkout.newPassword.PasswordConfirm' | translate }}">
          <a class="etsBtn login submitButton" (click)="passwordForgotReset()">{{ 'checkout.newPassword.ResetPassword' | translate }}</a>
        </form>
      </div>
    </ng-container>
  </div>
</section>
