<div *ngIf="this.showPackageButton" (click)="openPackage()" class="season_ticket_mobile">
  <span>{{globalConfig.ticketing.BtnPackageTicketWidgetText}}</span>
</div>
<div [class.off]="!details" data-anchor="order" class="ticketBox" [attr.data-event-id]="identifier">
  <ngx-spinner name="ticketingSpinner" [fullScreen]="true" type="ball-clip-rotate" size="medium">
    @if (identifier.startsWith('777') && (config.getShopIdentifierSubjectValue === 'cd9a6e1b36f3f5dcf773c3156adbbc34' || config.getShopIdentifierSubjectValue === 'c60d30ec4c1d5c9e497030fa63779354')) {
      <p class="loading spinnerText">Bitte warten, die Buchung des Paketes kann einige Zeit in Anspruch nehmen.</p>
    } @else {
      <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
    }

  </ngx-spinner>
  @if (details?.isBookable) {
    <form class="tabBox" *ngIf="details && details.ticketStatus">
      @if (!globalConfig.misc.onlyBestPlace) {
      <div class="menuButtons">
        <div *ngIf="details.ticketSeatmap || globalConfig.misc.onlyBestPlace" class="buttonHolder etsWrapper controllers clearfix">
          <div id="tabBtnBestPlace" data-tab="ticket" class="etsButton etsBtn tab ticket" [class.active]="!showPlan" (click)="showTab('ticket')">
            <div class="desktop">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
              <span class="desktop">{{'ticket.chooseTicket' | translate}}</span>
            </div>
            <span class="mobile">{{'ticket.bestPlace' | translate}}</span>
          </div>
          <div data-tab="plan" *ngIf="details.ticketSeatmap && !globalConfig.misc.onlyBestPlace" data-seatmap-resize-trigger (click)="showTab('plan')" class="etsButton etsBtn tab plan" [class.active]="showPlan">
            <div class="desktop">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
              <span class="desktop">{{'ticket.bookingInPlan1' | translate}} {{'ticket.bookingInPlan2' | translate}}</span>
            </div>
            <span class="mobile">{{'ticket.bookingInPlan2' | translate}}</span>
          </div>
          <div *ngIf="this.showPackageButton" class="etsButton etsBtn tab season_ticket" (click)="openPackage()" [class.active]="packageButtonActive">
            {{globalConfig.ticketing.BtnPackageTicketWidgetText}}
          </div>
        </div>
      </div>
      }
      <div class="etsContent">
        <div id="ticketid" #ticketid class="tabContent clearfix">
          <div class="clearfix">
            <div class="flexTable planTable">
              <div class="header">
                <div class="category">{{'ticket.categoryHeader' | translate}}</div>
                <div class="priceGroup">{{'ticket.priceGroupHeader' | translate}}</div>
                <div class="price">{{'ticket.priceHeader' | translate}}</div>
                <div class="quantity">{{'ticket.quantityHeader' | translate}}</div>
              </div>
              <!-- etsPage=detail-92004  For Dev and Testing-->
              <div class="etsContent clearfix">
                <ng-container *ngIf="globalConfig.ticketing.groupbestseat === 0">
                  <ng-container  *ngFor="let ticket of details.tickets; let l = index;">
                    <div class="line" [class.newBlock]="details.tickets[l-1] && details.tickets[l-1].categoryName !== details.tickets[l].categoryName">
                      <div class="category clearfix">
                        <span [ngClass]="'indicatorLight gray ' + (ticket.availabilityStatus >= 0 && ticket.availabilityStatus <= 2 ? 'status' + ticket.availabilityStatus : '')"></span>
                        <span class="categoryName">{{ticket.categoryName}}</span>
                      </div>
                      @if (config.getShopIdentifier() === 'aedb4fee802309b6f3fd4d676b9594d1' && ticket.priceGroupName === 'Porsche Mitarbeiter') {
                        <div class="priceGroup" style="color: #d5001c !important;">{{ticket.priceGroupName}}</div>
                      } @else if (config.getShopIdentifier() === 'dde1ebcd42899070565e45bc603d5b26' && ticket.priceGroupName === 'lbsf Corporate Benefits') {
                        <!-- ! Special display of pricegroupname in shophash dde1ebcd42899070565e45bc603d5b26 -->
                        <div class="priceGroup" style="font-weight: bold;">{{ticket.priceGroupName}}</div>
                      } @else {
                        <div class="priceGroup">{{ticket.priceGroupName}}</div>
                      }
                      <div class="price">
                        <!-- ! Special display of price for specific pricegroupname in shophash dde1ebcd42899070565e45bc603d5b26 -->
                        @if (config.getShopIdentifier() === 'dde1ebcd42899070565e45bc603d5b26' && ticket.priceGroupName === 'lbsf Corporate Benefits') {
                          <span data-same-width="priceBox" class="priceFormatted" style="font-weight: bold;">{{ticket.price | currency}} EUR</span>
                        } @else {
                          <span data-same-width="priceBox" class="priceFormatted">{{ticket.price | currency}} EUR</span>
                        }
                      </div>
                      <div class="quantity">
                        <ng-container *ngIf="ticket.maxSeats > 0; else noTicketsAvailable">
                          <ng-container *ngIf="(config.getShopIdentifier() === '8885ce6ed4ed7790ae600941e002a788' || config.getShopIdentifier() === '9c8c5cdf61d54ba702685105e151bb34') && (ticket.priceGroupId === '7039' || ticket.priceGroupId === '7040'); else regularTicketSelect">
                            <!-- ! Special Case for DE UEFA Shop 8885ce6ed4ed7790ae600941e002a788 AND EN 9c8c5cdf61d54ba702685105e151bb34 -->
                            <ng-container *ngIf="ticket.priceGroupId === '7039'">
                              <select *ngIf=" ticket.maxSeats >= 5; else noTicketsAvailable" #amountSelect  (change)="checkAddToCard($event)" class="small" data-field-id="{{ticket.eventDateId}}" data-field-name="cartItems">
                                <option value="" selected></option>
                                <option value="5">
                                  5
                                </option>
                              </select>
                            </ng-container>
                            <ng-container *ngIf="ticket.priceGroupId === '7040'">
                              <select *ngIf="ticket.maxSeats >= 11; else noTicketsAvailable" #amountSelect  (change)="checkAddToCard($event)" class="small" data-field-id="{{ticket.eventDateId}}" data-field-name="cartItems">
                                <option value="" selected></option>
                                <option value="11">
                                  11
                                </option>
                              </select>
                            </ng-container>

                          </ng-container>
                          <ng-template #regularTicketSelect>
                            <select #amountSelect (change)="checkAddToCard($event)" class="small" data-field-id="{{ticket.eventDateId}}" data-field-name="cartItems">
                              <option value="" selected></option>
                              <option *ngFor="let n of numSequence(ticket.maxSeats); let i = index;">{{i + 1}}</option>
                            </select>
                          </ng-template>
                        </ng-container>
                        <ng-template #noTicketsAvailable>
                          <span>{{'ticket.notAvailable' | translate}}</span>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="globalConfig.ticketing.groupbestseat === 1">
                  <ng-container *ngFor="let tickets of ticketData | keyvalue: unsorted; let x = index;">
                    <div class="line newBlock">
                      <div class="category clearfix">
                        <span [ngClass]="'indicatorLight gray ' + (tickets.value[0].availabilityStatus >= 0 && tickets.value[0].availabilityStatus <= 2 ? 'status' + tickets.value[0].availabilityStatus : '')"></span>
                        <span class="categoryName">{{tickets.value[0].categoryName}}</span>
                      </div>
                      <div class="priceGroup">{{tickets.value[0].priceGroupName}}</div>
                      <div class="price"><span data-same-width="priceBox" class="priceFormatted">{{tickets.value[0].price | currency}} EUR</span></div>
                      <div class="quantity">
                        <select #amountSelect *ngIf="tickets.value[0].maxSeats > 0" (change)="checkAddToCard($event)" class="small" data-field-id="{{tickets.value[0].eventDateId}}" data-field-name="cartItems">
                          <option value="" selected></option>
                          <option *ngFor="let n of numSequence(tickets.value[0].maxSeats); let i = index;">{{i + 1}}</option>
                        </select>
                        <span *ngIf="tickets.value[0].maxSeats <= 0">{{'ticket.notAvailable' | translate}}</span>
                      </div>
                    </div>
                    <div *ngIf="tickets.value.length > 1" class="bookingoptions">
                      <div class="line" *ngFor="let item of tickets.value | slice:1">
                        <div class="category clearfix">
                        </div>
                        <div class="priceGroup">{{item.priceGroupName}}</div>
                        <div class="price"><span data-same-width="priceBox" class="priceFormatted">{{item.price | currency}} EUR</span></div>
                        <div class="quantity">
                          <select #amountSelect *ngIf="item.maxSeats > 0" (change)="checkAddToCard($event)" class="small" data-field-id="{{item.eventDateId}}" data-field-name="cartItems">
                            <option value="" selected></option>
                            <option *ngFor="let n of numSequence(item.maxSeats); let i = index;">{{i + 1}}</option>
                          </select>
                          <span *ngIf="item.maxSeats <= 0">{{'ticket.notAvailable' | translate}}</span>
                        </div>
                      </div>
                    </div>

                    <div id="categoryInfo{{x}}" *ngIf="tickets.value.length > 1 && tickets.value[0].maxSeats > 0" class="categoryInfo">
                      <a class="expander" (click)="openTicketCategoryOptions(x)">
                        <svg class="categoryChevron" id="categoryChevron" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <p id="bookingInfos">{{'ticket.additionalBookingOptions' | translate}}</p>
                        <div id="categoryCount" class="categoryCount">{{tickets.value.length - 1}}</div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="flexTable infoTable">
              <div class="header">{{'ticket.informationHeader' | translate}}</div>
              <div class="etsContent">
                <div class="etsInfo">
                  <div class="line"><span class="indicatorLight status2"> </span> {{'ticket.ticketsAvailable' | translate}}</div>
                  <div class="line"><span class="indicatorLight status1"> </span> {{'ticket.restTicketsAvailable' | translate}}</div>
                  <div class="line"><span class="indicatorLight status0"> </span> {{'ticket.noTicketsAvailable' | translate}}</div>
                </div>
                <div class="shippingCosts">{{'ticket.priceIncludingTaxDisclaimer1' | translate}}
                  <app-ets-overlay title="Unsere Versandgebühren" linkTitle="{{ 'ticket.shippingFee' | translate }}">
                    <div class="scroller">
                      <p class="description">{{ 'ticket.shippingInformations' | translate }}<br>
                        {{ 'ticket.shippingInformations2' | translate }}</p>
                    <ul class="optionsList">
                      <li class="clearfix" *ngFor="let shipping of details.shipping">
                        <div class="info">
                          <h3>{{shipping.title}}</h3>
                        </div>
                        <div class="price">{{shipping.price}}</div>
                      </li>
                    </ul>
                    </div>
                  </app-ets-overlay>
                </div>
                <div class="etsInfo" [hidden]="details?.organizerName === ''">
                  {{ 'ticket.organisator' | translate}}: {{details?.organizerName}}
                </div>
              </div>
            </div>
          </div>
          <div class="cartAction clearfix">
            <div class="info">
              {{'ticket.cartActionInfo' | translate}}
            </div>
            <div class="etsButton">
              <a class="etsBtn addToCart submitButton" [class]="(allowAddToBasket) ? '' : 'inactive'" (click)="(allowAddToBasket) && addToBasket()" data-needs-field="cartItems">{{'ticket.cartActionButton' | translate}}</a>
            </div>
          </div>
        </div>
        <div #planid id="planid" class="tabContent">
          <div class="seatmapv5">
            <app-ets-accordion>
              <app-ets-accordion-child title="{{'ticket.filterbyCategory' | translate}}">
                <div class="flexTable planTable">
                  <div class="etsContent clearfix">
                    <ng-container *ngIf="filterCategories">
                      <ng-container *ngFor="let category of filterCategories; let l = index">
                        <div class="line" [class.newBlock]="filterCategories[l-1] && filterCategories[l-1].categoryName !== filterCategories[l].categoryName" data-active data-price-category-id="{{category.categoryNumber}}">
                          <div class="category clearfix">
                            <span class="indicatorLight gray " [style.background-color]="category.color"></span>
                            <span class="categoryName">{{category.categoryName}}</span>
                          </div>
                          <div class="priceGroup">{{category.priceGroupName}}</div>
                          <div class="price"><span data-same-width="priceBox" class="priceFormatted">{{category.price | currency}} EUR</span>
                          </div>
                          <div class="filter ">
                            <a class="etsBtn actionButton filterBtn" [class.active]="filteredCatIds.indexOf('p' + category.internalKey) >= 0" (click)="filterByPriceCategory(category.internalKey)"></a>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div class="line showAllCategoriesInSeatmap off">
                      <div class="category">
                        <a>Zeige alle Preiskategorien</a>
                      </div>
                    </div>
                  </div>
                </div>
              </app-ets-accordion-child>
            </app-ets-accordion>
            <div class="shippingCosts">{{ 'ticket.priceIncludingTaxDisclaimer1' | translate }}<app-ets-overlay title="Unsere Versandgebühren" linkTitle="{{ 'ticket.shippingFee' | translate}}">
              <div class="scroller">
              <p class="description">{{ 'ticket.shippingInformations' | translate }}<br>
                {{ 'ticket.shippingInformations2' | translate }}</p>
              <ul class="optionsList">
                <li class="clearfix" *ngFor="let shipping of details.shipping">
                  <div class="info">
                    <h3>{{shipping.title}}</h3>
                  </div>
                  <div class="price">{{shipping.price}}</div>
                </li>
              </ul>
              </div>
            </app-ets-overlay>.
            </div>
            <div class="ticketSelections clearfix off" [class.off]="seatmapTickets.length === 0">
              <label>{{ 'ticket.selectedTickets' | translate }}</label>
              <div class="ticketBox">
                <ul class="tickets">
                  <li class="clearfix" *ngFor="let ticket of seatmapTickets">
                    <input type="hidden" name="seatNumbers[]" value="{{ticket.seatNumber}}" />
                    <div class="priceBox clearfix">
                      <div class="priceGroup">
                        <select class="priceGroupBox small" name="priceGroups[]" (change)="changePrice(ticket.id, $event)">
                          <option *ngFor="let priceGroup of ticket.priceGroups" data-price="{{priceGroup.priceFormatted}}}" data-price-raw="{{priceGroup.price}}}" value="{{priceGroup.id}}">{{priceGroup.title}}</option>
                        </select>
                      </div>
                      <div class="price">{{ticket.price | currency}} EUR</div>
                    </div>
                    <div class="infoActions clearfix">
                      <div class="info">
                        {{ticket.info}}
                      </div>
                      <div class="actions">
                        <app-ets-overlay *ngIf="!!ticket.seatViewUrl" classList="actionButton photoTip" title="Sicht des Tickets" linkTitle="">
                          <div class="scroller">
                            <div class="line">
                              {{ticket.info}}
                            </div>
                            <div *ngIf="ticket.seatViewUrl !== ''" class="imageBox" [ngStyle]="{'background-image': 'url(' + ticket.seatViewUrl + ')'}">
                            </div>
                          </div>
                        </app-ets-overlay>
                        <a class="actionButton delete" (click)="removeSeat(ticket.id)"></a>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="totalPrice clearfix">
                  <div class="priceGroup totalPrice">
                    {{ 'ticket.totalPrice' | translate }}
                  </div>
                  <div class="price">{{priceTotal | currency}} EUR</div>
                </div>

                <div class="flexTable planTable">
                  <div class="etsContent clearfix">
                  </div>
                </div>

                <div class="actions mainActions">
                  <a class="etsBtn addToCart submitButton" (click)="addSeatmapToBasket()">{{ 'ticket.cartActionButton' | translate }}</a>
                </div>
              </div>
            </div>

            <div #seatmapv5 class="seatmapv5">
              <div class="seatmapHolder" #seatmapHolder>
                <div id="seatmap" class="map-container aspect-4_3" #seatmapContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [innerHTML]="details.ticketMemoText" class="memoTextInfo">

        </div>
        <button *ngIf="config.getCompleteShop() || config.getPreview()" style="float: right;" (click)="back()" style="float: left;" class="etsBtn ticket">{{'btnBack' | translate}}</button>
      </div>
    </form>
  } @else {
    <div *ngIf="details" class="contentPage">
      <h4>{{ 'ticket.stateNotAvailableHeadline' | translate }}</h4>
      <div *ngIf="details.eventDateNote !== ''">{{details.eventDateNote}}</div>
      <div *ngIf="details.eventDateNote === '' && globalConfig.misc.webshopTimeFilterCancelText !== ''" [innerHtml]="globalConfig.misc.webshopTimeFilterCancelText"></div>
      <div *ngIf="details.eventDateNote === '' && globalConfig.misc.webshopTimeFilterCancelText === ''" class="contentText">
        <p>{{ 'ticket.stateNotAvailableText' | translate }}</p>
        <p>{{ 'ticket.stateNotAvailableClosure' | translate }}</p>
      </div>
    </div>
  }
</div>

<div class="ets-tooltip" [class.off]="!(showSeatTooltip || showBlockTooltip)" #tooltip>
  <div class="closer" *ngIf="overlayActive" (click)="hideOverlay()"></div>
  <div class="blockTip" *ngIf="showBlockTooltip">
    <div class="line" *ngFor="let key of objectKeys(priceCategories)">
      <span class="etsTitle">
           {{priceCategories[key].title}}
      </span> -
      <span class="price">
        {{priceCategories[key].priceFormatted | currency}}€
      </span> -
      <span class="seats">
        @if (priceCategories[key].totalCount > 1) {
          {{priceCategories[key].totalCount}} {{'ticket.seatsAvailable' | translate}}
        } @else if (priceCategories[key].totalCount == 1) {
          {{priceCategories[key].totalCount}} {{'ticket.seatAvailable' | translate}}
        } @else {
          {{'ticket.noSeatsAvailable' | translate}}
        }
      </span>
    </div>
  </div>
  <div class="blockTip" *ngIf="showSeatTooltip">
    <div class="line">
      {{seatmapTooltip.info}}
    </div>
    <div *ngIf="seatmapTooltip.seatViewUrl !== ''" class="imageBox_overlay" [ngStyle]="{'background-image': 'url(' + seatmapTooltip.seatViewUrl + ')'}">
        <div class="enlarge" [class.fired]="overlayActive" (click)="showOverlay($event)"></div>
    </div>
  </div>
</div>
<div #overlay class="overlayTheater" *ngIf="overlayActive"></div>
